.bg {
  width: 100% !important;
  height: 100%;
  box-sizing: border-box;

  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative !important; */
  padding-left: 80px !important;
  padding-top: calc(100vh / 3);

  background-position: bottom;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

.header_text {
  font-weight: bold !important;
}
.sav {
  height: 100vh;
}
.bg h5,
.bg h4 {
  color: #ffffff;
  font-size: 55px;
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  line-height: 120%;
}
.bg_text {
  color: #ffffff !important;
  font-size: 58px !important;
  font-family: 'Roboto' !important;
  font-weight: bold !important;

  font-style: normal !important;
  line-height: 120% !important;
}
.btn_custom_color .span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn_custom_color .span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.btn_custom_color:hover:enabled .span {
  padding-right: 25px;
}

.btn_custom_color:hover:enabled .span:after {
  opacity: 1;
  right: 0;
}

.bg p {
  color: #eaecf0;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  font-style: normal;
}

.icons {
  width: 152px;
  float: left;
}
.icon_n_text span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  color: #eaecf0;
  padding-left: 10px;
  margin-top: 10px;
  display: inline-block;
}

.star {
  top: calc(100vh / 3.1);
}

.sign_up {
  padding: 20px 80px;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
}

.form {
  margin-top: 50px;
}

.form label {
  color: var(--text-primary) !important;
}

.input_container input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 14px !important;
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 85px;
  height: 67px;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  /* gray/500 */

  color: #6b7280;
}
.input_container input:focus {
  outline: none !important;
  border: none !important;
}
.btn_custom_color {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.btn_custom_color:hover {
  color: #ffffff;
}
.buttons {
  margin-top: 20px;
}
.btn_g {
  border: 1px solid #d0d5dd;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}
.btn_g:hover {
  border: 1px solid var(--primary-color);
}

form .form-control:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .sign_up {
    height: 100vh !important;
    padding: 10px 40px !important;
  }
}
