/* Global css forr non MUI */

:root {
  --dark-primary-color: #7a2571;
  --primary-color: #af3585;
  --opac-4-primary-color: '#FFA6F5';
  --opac-primary-color: #ffd3fa;
  --text-primary: #4b5563;
  --gray-text: #71717a;
}

.bold-main-text {
  color: var(--text-primary) !important;
  font-weight: bold;
}

.text-main-color,
.table thead th,
table thead th,
.thead th {
  color: var(--text-primary) !important;
}

small,
.normal-text {
  color: #4b5563 !important;
}

.row-container > div:nth-child(odd) {
  background: #f9fafb;
}
