.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}
.card small {
  font-weight: bold;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

body {
  /* background-color: #fff !important; */
}
.customized_row {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 4px;
}
.delete_product {
  background: #dc2626;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 17px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* white */

  color: #ffffff;
}
.delete_product:hover {
  color: #dc2626;
}
.cancel_delete {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}
.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}
form {
  width: 100% !important;
}

img {
  display: inline !important;
}
.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.btn_controls .confirm {
  background: var(--primary-color);
  /* blue/600 */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
}
.btn_controls .confirmed {
  /* blue/50 */

  background: #eff6ff;
  /* blue/600 */

  border: 0.5px solid var(--primary-color);
  color: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.confirm_order {
  /* blue/600 */

  background: var(--primary-color);
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: white;
  width: 47%;
}
.confirm_order:hover {
  color: white;
}
.cancel:hover {
  color: #000000;
}
.cancel {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: #374151;
  width: 47%;
}
.btn_controls .confirmed {
  /* blue/50 */

  background: #eff6ff;
  /* blue/600 */

  border: 0.5px solid var(--primary-color);
  color: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.btn_controls .confirmed_disabled {
  background: #f9fafb;
  /* gray/400 */
  color: #9ca3af;

  border: 0.5px solid #9ca3af;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-color) !important;

  /* or 36px */

  /* blue/600 */
}
div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}

div.percent2 {
  background: #fee2e2;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  color: #991b1b !important;
  justify-content: ' space-around';
}
.wallet {
  background: linear-gradient(45deg, #8c2d91 0%, var(--primary-color) 100%);
  box-shadow: 7.87342px 9.84177px 15.7468px rgba(0, 0, 0, 0.05);
  border-radius: 19.6835px;
  padding: 13px;
  color: #ffffff;
}
.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}
.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}
.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 16px !important;
  /* padding-left: 23px !important; */
  padding-right: 9px !important;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
}
.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 16px !important;
  /* padding-left: 23px !important; */
  padding-right: 9px !important;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
  padding-left: 13px;
  padding-right: 9px;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}

.hold_input {
  position: relative !important;
}

.hold_input img {
  position: absolute;
  top: 28%;
  left: 25px !important;
}
.hold_input input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.export_btn {
  height: 40px;

  /* blue/600 */

  background: var(--primary-color);
  /* blue/600 */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: #ffffff;
  padding-top: 0;
}
.filters button {
  padding: 5px 16px;
  gap: 8px;

  width: 108px;
  height: 37px;
  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}
.export_btn:hover {
  color: #ffffff;
  font-weight: bold;
}
.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}
.modal_content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  color: #1f2937;
}
.modal_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
}

.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}

.modal_content label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}
.product_selected {
  /* width: 70px; */
  height: 24px;
  padding: 3px 4px 3px 3px;

  /* Base/White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* text-align: center; */

  /* Gray/700 */

  color: #344054;
  display: inline-block;
  margin-right: 4px;
}
.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
.abandoned {
  padding: 2px 8px;

  width: 78px;
  height: 22px;

  /* Gray/100 */

  background: #f2f4f7;
  color: #344054;
  border-radius: 16px;
}
.pending {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffedd5;
  color: #c2410c;
  border-radius: 16px;
}

.refunded {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  /* Gray/100 */
  background: #5f8cee;
  color: #fff;
  border-radius: 16px;
}

.abandoned {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffe3d5;
  color: red;
  border-radius: 16px;
}
.customer {
  padding: 2px 8px;
  width: 69px;
  height: 22px;
  background: #ecfdf3;
  color: #027a48;
  border-radius: 16px;
}

.pagination_button_container button {
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

@media only screen and (max-width: 480px) {
  /* .hold_input img {
        position: absolute;
        top: 28%;
        left: 25px !important;
    } */
  .tr p {
    font-size: 10px !important;
  }
  .action_button {
    display: inline-block;
    margin-left: 10px !important;
  }

  .sub_total {
    position: relative !important;
  }

  .summary {
    height: 400px;
  }
}
