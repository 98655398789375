* {
  font-family: 'Roboto', sans-serif !important;
}
.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}
body {
  font-family: 'Roboto', sans-serif !important;
}

form {
  width: 100% !important;
}
.admin_text {
  font-size: 17px !important;
}
.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.header h4 {
  font-weight: 700;
}

.sub_header {
  display: flex;
  justify-content: space-between;
}

.sub_header .edit_btn .btn {
  padding: 5px 20px !important;
  float: right;
}

.sub_header .p_info h6 {
  font-size: 18px;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 2;
}
.form_group input {
  border-radius: 8px !important;
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  height: 35px !important;
}
.sub_header .p_info p {
  color: #999 !important;
  font-size: 14px;
  line-height: 1;
}

.row {
  border-top: 1px solid rgba(100, 100, 100, 0.1);
  padding: 20px 0 0px !important;
  margin: 0 !important;
}

.row p {
  font-size: 14px;
  margin-top: 5px;
}

.row div:nth-of-type(1) p {
  color: #667085;
  font-weight: 500;
  font-size: 13px;
}

.row div:nth-of-type(2) p {
  font-weight: 400;
  font-size: 13px;
  color: #101828;
}

.row div p span {
  padding: 3px 5px;
  line-height: 0px;
  border-radius: 5px;
  border: 1px solid #999;
}

.row .bi_envelope {
  font-size: 18px;
  vertical-align: middle;
  margin-right: 6px;
}

.edit_btn .btn {
  font-size: 13px;
}

.edit_btn .cancel_btn {
  border: 1px solid lightgray;
  /* padding: 5px 10px !important; */
  border-radius: 6px;
  transition: 0.3s ease;
  /* font-size: 13px; */
}

.row textarea,
.row input {
  font-size: 12px;
}

.row input::placeholder,
.row textarea::placeholder {
  font-size: 12px;
  color: #999;
}

.row .country_list,
.row .country_list option,
.row #country_list,
.row .country_list,
.row .country_list option,
.row #states,
.row .country_list,
.row .country_list option,
.row #country_list option,
.row .country_list,
.row .country_list option,
.row #states option {
  border: 1px solid lightgray;
  width: 100% !important;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 13px;
}
.row .country_list,
.row .country_list option,
.row #country_list option,
.row .country_list,
.row .country_list option,
.row #states option {
  cursor: pointer !important;
  color: #999;
}

option img {
  width: 100px !important;
  z-index: 999;
}

.row .country_list,
.row .country_list option,
.row #country_list,
.row .country_list,
.row .country_list option,
.row #states {
  color: #999 !important;
  cursor: pointer !important;
}

.row #country_list::selection,
.row #country_list:focus,
.row #country_list:valid,
.row #states::selection,
.row #states:focus,
.row #states:valid {
  border: 1px solid lightgray;
}

input::-webkit-inner-spin-button,
input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

#updated {
  display: block;
}

#editupdate {
  display: none;
}

.cstm_file {
  position: relative;
  height: 150px;
}

.cstm_file .cstm_file_label {
  height: fit-content;
  text-align: center;
  border-radius: 8px;
}

.cstm_file .cstm_file_label::after {
  display: none !important;
}
.product_selected {
  /* width: 70px; */
  height: 24px;
  padding: 3px 4px 3px 3px;

  /* Base/White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* text-align: center; */

  /* Gray/700 */

  color: #344054;
  display: inline-block;
  margin-right: 4px;
}
.cstm_file input {
  position: absolute;
  height: 80% !important;
  z-index: 999999 !important;
  border: 2px solid black !important;
  cursor: pointer;
}

.cstm_file .cstm_file_label p {
  font-size: 10px !important;
}

.cstm_file .cstm_file_label p span {
  border: none !important;
  color: royalblue;
}
.holder {
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  min-height: 100vh;
  box-sizing: border-box;
}

.cstm_file .cstm_file_label p i {
  font-size: 25px !important;
  line-height: 0px;
  background: rgba(100, 100, 100, 0.2);
  padding: 0 6px;
  border-radius: 50%;
}
.wrapper h1 {
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 30px;
}
.content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  text-align: center;

  /* Gray/500 */

  color: #667085;
}
h5 {
  margin-bottom: 20px !important;
}
.subscription p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px !important;

  /* or 150% */

  text-align: center;

  /* Gray/500 */

  color: #667085;
}
.subscribe_button_container button {
  padding: 10px 42px;

  /* Chat Blue */

  background: #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: block;
  width: 100%;
  text-align: center;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: #53138d !important;
  /* or 36px */

  /* blue/600 */

  color: #53138d;
}
.numbers div small {
  font-style: normal;

  font-size: 11px;
  color: #6b7280;
}
.wrapper .header .wallet_actions .wallet_button .transfer {
  /* width: 100%; */
  width: 130px;
  height: 44px;

  /* white */

  background: #ffffff;
  /* gray/700 */

  border: 1px solid #374151;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* gray/700 */

  color: #374151;
  transition: all 0.5s;
}
.top_up_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.top_up_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.top_up_button:hover span {
  padding-right: 25px;
}

.top_up_button:hover span:after {
  opacity: 1;
  right: 0;
}

/*  */
.transfer span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.transfer span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.transfer:hover span {
  padding-right: 25px;
}

.transfer:hover span:after {
  opacity: 1;
  right: 0;
}

.wrapper .header .wallet_actions .wallet_button .transfer:hover {
  border: 1px solid #53138d !important;
  /* color: #FFFFFF; */
}

.wrapper .header .wallet_actions .wallet_button .top_up_button {
  transition: all 0.5s;

  background: #53138d !important;

  border: 1px solid #53138d !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  padding: 10px 16px;
  width: 130px;
  height: 44px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}

div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}
div.percent2 {
  background: #fee2e2;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  color: #991b1b !important;
  justify-content: ' space-around';
}
div.percent small {
  font-size: 10px;
}
div.percent2 small {
  font-size: 10px;
  color: #991b1b !important;
}
.wallet {
  width: 100% !important;
  height: 100% !important;

  /* background-image:url('/public/assets/crc.png'); */

  /* background-repeat:no-repeat !important;

    border-radius:30px !important; */

  position: relative !important;
}
.wallet .content {
  padding: 32px 41px;
  color: #ffffff;
  position: relative !important;
  z-index: 31 !important;
}
.wallet .img {
  width: 100% !important;

  position: absolute !important;
  z-index: 0.1 !important;
  margin: 10px 10px !important;
  height: 224px;
}
.wallet .content .sub_text {
  color: #bfdbfe;
  font-size: 10px;
  margin-bottom: 10px;
}
.wallet .content .content_body {
  color: #bfdbfe;
  font-size: 10px;
  margin-top: 50px;
}
.wallet .content .content_body .bank_info .name {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 1px;
}
.wallet .content .content_body .bank_info img {
  height: 30px;
}
.analytics {
  margin-top: 40px !important;
}
.analytics .customized_row {
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-column-gap: 30px;
}
.analytics .customized_row1 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 15px;
}
.wallet .content .content_body .bank_info {
  display: flex;
  justify-content: space-between;
}
.wallet .content .content_body .account_number {
  font-weight: 400;
  font-size: 15.7468px;
  font-weight: 600;

  color: #ffffff;
  margin-bottom: 10px;
}
.wallet .footer {
  text-align: right;
}
.wallet .footer div p {
  font-size: 14px;
  color: #53138d;
  font-style: normal;
  font-weight: 500;
}
.wallet .footer div p span {
  cursor: pointer;
}

.wallet .content .content_header {
  display: flex;
  justify-content: space-between;
}
.wallet .footer {
  /* position:absolute;
    bottom:0; */
  border-top: 1px solid #e5e7eb;
  width: 100%;
  height: 34px;
  padding: 4px;
}

.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}
.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}
.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: 500;
  font-size: 12px;
  padding: 16px;
  padding-left: 0 !important;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
}
.card2 {
  background: #ffffff !important;

  border: 1.12465px solid #e5e7eb;
  box-shadow: 0px 1.12465px 3.37394px rgba(16, 24, 40, 0.1),
    0px 1.12465px 2.24929px rgba(16, 24, 40, 0.06) !important;
  border-radius: 8.99717px !important;
}
.modal_content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  color: #1f2937;
}
.modal_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
}
.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}

.modal_content label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}
.modal_content .modal_btn {
  background: #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}

@media screen and (max-width: 860px) {
  .wallet {
    width: 100% !important;
  }
  .card2 {
    width: 40% !important;
  }
  .analytics .customized_row {
    display: block !important;
  }
}

@media screen and (max-width: 757px) {
  .wrapper h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 150% */

    text-align: center;

    /* Gray/500 */

    color: #667085;
  }
}

@media only screen and (max-width: 320px) {
  .wallet {
    background-size: cover !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallet {
    background-size: cover !important;
  }

  .top_up_button:hover span:after {
    opacity: 1;
    right: 0;
  }

  /*  */
  .transfer span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .transfer span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .transfer:hover span {
    padding-right: 25px;
  }

  .transfer:hover span:after {
    opacity: 1;
    right: 0;
  }

  .wrapper .header .wallet_actions .wallet_button .transfer:hover {
    border: 1px solid #53138d !important;
    /* color: #FFFFFF; */
  }

  .wrapper .header .wallet_actions .wallet_button .top_up_button {
    transition: all 0.5s;

    background: #53138d !important;

    border: 1px solid #53138d !important;

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 4px !important;
    padding: 10px 16px;
    width: 130px;
    height: 44px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* White */

    color: #ffffff;
  }

  div.percent {
    background: #d1fae5;
    border-radius: 12px;
    padding: 0px 10px;
    display: inline-block;
    width: '67px';
    height: '25px';
    border-radius: '12px';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    justify-content: ' space-around';
  }
  div.percent2 {
    background: #fee2e2;
    border-radius: 12px;
    padding: 0px 10px;
    display: inline-block;
    width: '67px';
    height: '25px';
    border-radius: '12px';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    color: #991b1b !important;
    justify-content: ' space-around';
  }
  div.percent small {
    font-size: 10px;
  }
  div.percent2 small {
    font-size: 10px;
    color: #991b1b !important;
  }
  .wallet {
    width: 100% !important;
    height: 100% !important;

    /* background-image:url('/public/assets/crc.png'); */

    /* background-repeat:no-repeat !important;

    border-radius:30px !important; */

    position: relative !important;
  }
  .wallet .content {
    padding: 32px 41px;
    color: #ffffff;
    position: relative !important;
    z-index: 31 !important;
  }
  .wallet .img {
    width: 100% !important;

    position: absolute !important;
    z-index: 0.1 !important;
    margin: 10px 10px !important;
    height: 224px;
  }
  .wallet .content .sub_text {
    color: #bfdbfe;
    font-size: 10px;
    margin-bottom: 10px;
  }
  .wallet .content .content_body {
    color: #bfdbfe;
    font-size: 10px;
    margin-top: 50px;
  }
  .wallet .content .content_body .bank_info .name {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 1px;
  }
  .wallet .content .content_body .bank_info img {
    height: 30px;
  }
  .analytics {
    margin-top: 40px !important;
  }
  .analytics .customized_row {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-column-gap: 30px;
  }
  .analytics .customized_row1 {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 15px;
  }
  .wallet .content .content_body .bank_info {
    display: flex;
    justify-content: space-between;
  }
  .wallet .content .content_body .account_number {
    font-weight: 400;
    font-size: 15.7468px;
    font-weight: 600;

    color: #ffffff;
    margin-bottom: 10px;
  }
  .wallet .footer {
    text-align: right;
  }
  .wallet .footer div p {
    font-size: 14px;
    color: #53138d;
    font-style: normal;
    font-weight: 500;
  }
  .wallet .footer div p span {
    cursor: pointer;
  }

  .wallet .content .content_header {
    display: flex;
    justify-content: space-between;
  }
  .wallet .footer {
    /* position:absolute;
    bottom:0; */
    border-top: 1px solid #e5e7eb;
    width: 100%;
    height: 34px;
    padding: 4px;
  }

  .acc_number {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* identical to box height */

    /* Base/White */

    color: #ffffff;
  }
  .table {
    /* color: #6B7280 !important; */
    width: 100% !important;
  }
  .thead th {
    border-bottom: 1px solid #eaecf0 !important;
    border-top: 1px solid #eaecf0 !important;
    color: var(--text-primary) !important;
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
    padding-left: 0 !important;
  }

  .tr {
    height: 77px !important;
    border-bottom: 1px solid #eaecf0;
  }
  .tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    /* gray/500 */

    color: #6b7280;
  }
  .card2 {
    background: #ffffff !important;

    border: 1.12465px solid #e5e7eb;
    box-shadow: 0px 1.12465px 3.37394px rgba(16, 24, 40, 0.1),
      0px 1.12465px 2.24929px rgba(16, 24, 40, 0.06) !important;
    border-radius: 8.99717px !important;
  }
  .modal_content h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;

    color: #1f2937;
  }
  .modal_content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    /* gray/500 */

    color: #6b7280;
  }
  .modal_content .modal_header {
    display: flex;
    justify-content: space-between;
  }

  .modal_content label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: #344054;
  }
  .modal_content .modal_btn {
    background: #53138d;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #ffffff;
  }

  table,
  thead,
  tr,
  tbody,
  td {
    white-space: nowrap;
  }

  td:last-child,
  td:last-of-type {
    margin: auto;
  }
}

@media screen and (max-width: 860px) {
  .wallet {
    width: 100% !important;
  }
  .card2 {
    width: 40% !important;
  }
  .analytics .customized_row {
    display: block !important;
  }
}

@media screen and (max-width: 757px) {
  .wrapper h1 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* or 150% */

    text-align: center;

    /* Gray/500 */

    color: #667085;
  }
}

@media only screen and (max-width: 320px) {
  .wallet {
    background-size: cover !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallet {
    background-size: cover !important;
  }
}
@media screen and (max-width: 410px) {
  .sub_header .p_info p {
    font-size: 12px;
  }
  .row p span {
    font-size: 10px;
  }
}
@media screen and (max-width: 570px) {
  .sub_header .edit_btn .btn {
    float: none !important;
    margin-bottom: 10px;
  }

  .edit_btn .update_sect {
    margin-left: 0 !important;
  }

  .edit_btn .update_sect .cancel_btn {
    margin-left: 0 !important;
  }
}
