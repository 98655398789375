/* width */
.css-1mp0xne {
  max-width: 100% !important;
}

::-webkit-scrollbar {
  width: 5px;
}

form {
  width: 100%;
}

.spinning {
  display: inline-block;

  animation: spin 1s infinite linear !important;
}

#SvgjsSvg4925 {
  margin-top: 20px !important;
}

/* .apexcharts-toolbar {
    top: 25px !important;
} */

.star-rating {
  display: block;
  font-size: 17px;
}

.star {
  display: block;
}

.full-star {
  color: gold !important;
}

.half-star {
  color: gold !important;
  clip-path: inset(0 50% 0 0);
}

.empty-star {
  color: #ccc !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.initials {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  /* Gray/50 */

  background: #f9fafb;
  border-radius: 200px;
}

.tag_id {
  display: inline-block;
}

/* .MuiModal-root {
    z-index: 9999;
}

.MuiBackdrop-root {
    z-index: 9998;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.react-datepicker__input-container::before {
  content: url('/public/assets/icons/calenderIcon.svg');
  position: absolute;
  top: 50%;
  right: 0.7rem;
  transform: translateY(-50%);
  pointer-events: none;
}

.card-body small {
  font-weight: bold;
  color: var(--text-primary);
}

.sidebarM::-webkit-scrollbar {
  width: 5px;
  background: var(--primary-color) !important;
}

.remove_padding {
  padding-top: 30px !important;
}

.input_container input {
  /* padding-top: 10px !important; */
  /* padding-bottom: 10px  !important; */
  /* padding-left:   14px !important; */
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 85px;
  height: 67px;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  /* identical to box height, or 36px */

  /* text-align: center; */

  /* gray/500 */

  color: #6b7280;
}

.pending_badge {
  background: #ffedd5;
  border-radius: 16px;
  display: inline-block;
  padding: 0px 12px;
  font-weight: 500;
  font-size: 12px;
  /* identical to box height, or 18px */

  text-align: center;

  /* orange/700 */

  color: #c2410c;
}

.completed_badge {
  background: #ecfdf3;
  border-radius: 16px;
  display: inline-block;
  padding: 4px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-align: center;

  /* orange/700 */

  color: #027a48;
}

.verified_badge img {
  width: 12px;
  vertical-align: middle;
}

.verified_badge {
  background: #d1fae5;
  border-radius: 16px;
  display: inline-block;
  padding: 10px 8px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  /* width: 75px; */
  /* identical to box height, or 18px */
  /* orange/700 */
  vertical-align: middle;
  color: #065f46;
}

.cursor_pointer {
  cursor: pointer;
}

.not_verified_badge {
  background: #d1fae5;
  border-radius: 16px;
  display: inline-block;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  /* orange/700 */
  color: #ef4444;
  background: #fee2e2;
}

.ongoing_badge {
  background: #eff9fe;
  border-radius: 16px;
  display: inline-block;
  padding: 10px 8px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  text-align: center;
  /* identical to box height, or 18px */
  /* orange/700 */
  vertical-align: middle;
  color: var(--primary-color);
}

.rejected_badge {
  background: #eceff3;
  border-radius: 16px;
  display: inline-block;
  padding: 10px 8px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 0;
  text-align: center;
  /* identical to box height, or 18px */
  /* orange/700 */
  vertical-align: middle;
  color: #444;
}

.withdraw {
  padding: 4px 10px;
  /* gray/100 */
  background: #e4e4ece2;
  border-radius: 16px;
}

.success {
  background: #ecfdf3;
  padding: 4px 10px;
  border-radius: 16px;
}

.control_promos_img img {
  width: 20%;
}

.pending {
  background: #ffedd5;
  padding: 4px 10px;
  border-radius: 16px;
  color: #c2410c;
}

.failed {
  background: #fef3f2;
  padding: 4px 10px;
  color: #b42318;

  border-radius: 16px;
}

.top_up {
  background: #f4f4f5;
  padding: 4px 10px;
  color: #b42318;
  border-radius: 16px;
}

.wrapper {
  padding-top: 70px !important;
}

.input_container input:focus {
  outline: none !important;
  border: none !important;
}

.sidebarM::-webkit-scrollbar-thumb {
  background: var(--primary-color) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.inputs_n_label {
  color: var(--text-primary);
}

.inputs_n_label input::placeholder,
.inputs_n_label select option {
  color: var(--text-primary);
}

.inputs_n_label {
  position: relative !important;
}

.inputs_n_label .icon {
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 3%;
}

.position_settings {
  position: relative;
}

.position_settings .icon {
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 8%;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

body {
  font-family: 'Roboto', sans-serif !important;
}

.box_buttom_content {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.01em;

  color: #667085;
}

.relative_position {
  position: relative;
}

.absolute {
  position: absolute;
  width: 25%;
  top: 50%;
  right: 0;
}

.absolute_input {
  position: absolute;
  width: 13%;
  top: 50%;
  right: 0px !important;
}

.modal_bg {
  background: #ffffff;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.add_to_cart_content .buttons .cancel {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d6d1db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.add_to_cart_content .buttons button {
  display: inline-block;
  width: 49%;
}

.modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.hover:hover {
  color: #ffffff;
}

/* .table_responsive {
    display: block;
    width: 100% !important;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
} */

/* .right_wrapper{
    border-left:solid 1px red;
    padding-left:20px;
} */
.add_to_cart_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  /* gray/500 */

  color: #6b7280;
}

.add_to_cart_content .buttons .go_to {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: var(--primary-color);
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0 !important;
  color: #ffffff;
}

.map_filter {
  width: 25em;
  max-width: 90%;
  position: relative;
  top: 6px;
  /* z-index: 10000000000; */
  background: white;
  left: 10px;
}

.form_label label {
  font-weight: bold !important;
}

.btn_primary {
  /* blue/600 */

  background: var(--primary-color);
  color: #ffffff;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.btn_primary:hover {
  font-weight: bold;
  color: #ffffff;
}

.header_control {
  font-size: 14px;
  color: #4b5563;
}

.input_ {
  border-radius: 10px;
  width: 90%;
}

.btn_primary_outline {
  /* blue/600 */

  color: var(--primary-color);
  background-color: #ffffff;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid var(--primary-color);
}

.btn_primary_outline:hover {
  color: #8c2d91;
  font-weight: bold;
}

.relative_position span {
  position: absolute;
  top: 53%;
  left: 2%;
}

.relative_position input {
  text-indent: 20px !important;
}

.card_hold input {
  text-indent: 28px !important;
}

.relative_position1 {
  position: relative !important;
}

.relative_position1 img {
  position: absolute;
  right: 4%;
  top: 58%;
}
#ad_duration {
  -webkit-text-fill-color: #101828 !important;
  font-weight: bold !important;
}
#push_notification {
  -webkit-text-fill-color: #101828 !important;

  font-weight: bold !important;
}

.relative_position1 .img {
  position: absolute;
  right: 4%;
  top: 30%;
}

.add_heading {
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 60px;
  /* or 100% */

  /* blue/600 */

  color: var(--primary-color);
}

.popular {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  color: var(--primary-color);
  /* blue/100 */

  background: #dbeafe;
  border-radius: 7px;
  font-size: 12px;
}

.ad {
  /* white */

  background: #ffffff !important;
  /* Iris/100 */

  /* /shadow/lg */

  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px !important;
}

.ad_borderooo {
  border: 1px solid var(--primary-color) !important;
}

.transition {
  transition: height 0.3s ease, opacity 0.3s ease;
}

.retailer_product_edit_delete {
  width: 100%;
  margin: 10px 15px 0px 15px;
  text-align: center;
}

.retailer_product_edit {
  border-right: 1px solid #eaecf0;
  width: 50% !important;
  padding-top: 3px;
  display: inline-block;
  color: var(--primary-color);
}

.color_text {
  color: #9ca3af;
}

.categories {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 4px;
  font-size: 10px;
  margin-bottom: 5px;
  margin-right: 4px;
  background-color: #ffffff;
}

.small {
  font-size: 10px;
}

.pointer_cursor {
  cursor: pointer;
}

.retailer_product_delete {
  display: inline-block;
  width: 50% !important;
  text-align: center;
  color: #ef4444;
}

.box {
  border: 3px dashed #d1d5db;
  padding: 20px;
}

.btn_styles {
  padding: 10px 16px;
  gap: 8px;

  width: 130px;
  height: 44px;

  /* Chat Blue */

  background: var(--primary-color);
  /* Chat Blue */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  color: white;
}

.inputs_n_label label {
  font-weight: bold;
}

.label {
  font-weight: bold !important;
}

.import {
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

button:hover {
}

.add_outlet:hover {
  color: #fff;
  font-weight: bold;
}

.table_desc_header {
  font-weight: bold;
  font-size: 18px;
  color: #1f2937;
  margin-bottom: 10px !important;
}

.card-body small {
  /* font-weight: bolder !important; */
}

.style_cs {
  position: absolute !important;
}

.product_selected {
  /* width: 70px; */
  height: 24px;
  padding: 3px 4px 3px 3px;

  /* Base/White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* text-align: center; */

  /* Gray/700 */

  color: #344054;
  display: inline-block;
  margin-right: 4px;
}

.add_outlet {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.removeB {
  border-radius: unset !important;
}

.header h4 {
  font-weight: bold !important;
  color: #101828 !important;
}

.header p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* gray/600 */

  color: #4b5563;
}

.wrapper .header h4 {
  font-weight: bold !important;
  color: #101828 !important;
}

.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
  height: 30px !important;
}

.modal_content label {
  font-weight: 500;
  font-size: 14px;
  color: #374151;
}

.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.modal_btn:hover {
  color: #ffffff;
}

.wrapper .header p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* gray/600 */

  color: #4b5563;
}

div:focus {
  border: none;
  outline: none;
}

.hold_input4 {
  position: relative !important;
}
.hold_input_brand {
  position: relative !important;
}

.wallet_actions .wallet_button .transfer:hover {
  border: 1px solid var(--primary-color) !important;
  /* color: #FFFFFF; */
}

.top_up_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.top_up_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.top_up_button:hover span {
  padding-right: 25px;
}

.top_up_button:hover span:after {
  opacity: 1;
  right: 0;
}

.wallet_actions h4 {
  font-weight: bold !important;
  color: #101828 !important;
}

.wallet_actions .wallet_button .top_up_button {
  transition: all 0.5s;

  background: var(--primary-color) !important;

  border: 1px solid var(--primary-color) !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  padding: 10px 16px;
  width: 130px;
  height: 44px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}

.wallet_actions .wallet_button .transfer {
  /* width: 100%; */
  width: 130px;
  height: 44px;

  /* white */

  background: #ffffff;
  /* gray/700 */

  border: 1px solid #374151;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* gray/700 */

  color: #374151;
  transition: all 0.5s;
}

.border_buttom {
  border-bottom: solid 1px #cccccc !important;
  margin: 0 10px 0 10px;
}

.style_c {
  padding: 0 22px !important;
  /* padding: 0px 40px; */
}

.hold_input4 img {
  position: absolute;
  top: 29%;
  left: 3%;
}
.hold_input_brand img {
  position: absolute;
  top: 29%;
  left: 1%;
}

.hold_input4 input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}
.hold_input_brand input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.MuiBackdrop-root .MuiModal-backdrop .css-qqz3re-MuiBackdrop-root {
  background: transparent !important;
}

.rss:hover {
  background-color: inherit !important;
  text-decoration: none;
}

.btn_neutral {
  background-color: #dce2ea;
}

.adjust_p_margin {
  margin: 3px !important;
}

.new_mute {
  color: #797f8a;
}

.pointer {
  cursor: pointer;
}

.width_size {
  width: 72%;
}

.upper_space {
  margin-top: 33px;
}

button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

a {
  text-decoration: none !important;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}

@media screen and (min-width: 1200px) {
  .style_c {
    padding: 0px 22px !important;
  }
}

@media screen and (max-width: 700px) {
  .mobile_modal_size {
    width: 100% !important;
    max-height: 90% !important;
    outline: none !important;
    left: 50%;
  }

  .absolute {
    position: absolute;
    width: 40%;
    top: 50%;
    right: 0;
  }

  .control_text h4 {
    font-size: 16px !important;
  }

  .wrapper {
    padding-top: 10px !important;
  }

  .hold_input4 {
    width: 100% !important;
  }
  .hold_input_brand {
    width: 100% !important;
  }

  .table-responsive-sm table {
    width: 200% !important;
  }
}

@media only screen and (max-width: 480px) {
  .table-responsive-sm table {
    width: 200% !important;
  }

  .absolute_input {
    width: 23%;
  }

  .width_size {
    width: 60%;
  }

  .upper_space {
    margin-top: 20px;
  }
}
