* {
    font-family: 'Roboto', sans-serif !important;

}
.bg {
    width:100% !important;
    height: 100%;
    box-sizing: border-box;

    background-repeat: no-repeat;
    background-size: cover;
    /* position: relative !important; */
    padding-left: 80px !important;
    padding-top: calc(100vh / 3) ;

    background-position: bottom;

}
.header_text {
    font-weight: bold !important;
}
.sav {
    height: 100vh ;
}
.bg h5, .bg h4 {
    color: #FFFFFF;
    font-size: 55px;
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    line-height: 100%;


}
.bg_text {
    color: #FFFFFF !important;
    font-size: 58px !important;
    font-family: 'Roboto' !important;
    font-weight: bold !important;

    font-style: normal !important;
    line-height: 120% !important;
}
.btn_custom_color .span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .btn_custom_color .span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .btn_custom_color:hover:enabled .span {
    padding-right: 25px;
  }
  
  .btn_custom_color:hover:enabled .span:after {
    opacity: 1;
    right: 0;
  }

.bg p{
    color: #EAECF0;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    font-style: normal;


}

.image_upload {
    /* background-color: lightgray; */
    padding: 2rem 1.5rem;
    /* width: 100%; */
    /* display: grid; */
    /* place-content: center; */

}

.icons {
    width: 152px;
    float: left;
}
.icon_n_text span{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;

color: #EAECF0;
padding-left: 10px;
margin-top: 10px;
display: inline-block;
}

.star {
    top: calc(100vh / 3.1);
}

.sign_up {
    padding: 20px  80px ;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 110vh;
    overflow-y: scroll;
}

.input_container input{
    padding-top: 10px !important;
    padding-bottom: 10px  !important;
    padding-left:   14px !important;
    background: #FFFFFF;
    /* Gray/300 */
    
    border: 1px solid #D0D5DD;
    /* Shadow/xs */
    
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 85px;
    height: 67px;
    font-weight: 700;
    font-size: 30px;
    line-height: 150%;
    /* identical to box height, or 36px */

    text-align: center;

    /* gray/500 */

    color: #6B7280;
}
.input_container input:focus {
    outline: none !important;
    border: none !important;
}
.btn_custom_color {
    background-color: #53138D;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: all 0.5s;

}
.btn_custom_color:hover {
    color: #FFFFFF;


}
.buttons {
    margin-top: 20px;
}
.btn_g {
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;


}
.btn_g:hover {
    border: 1px solid #53138D;
}

form .form-control:focus{
    border-color: #53138D;
    box-shadow: none;
}

.signin_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    text-align: center;
    color: #888;
    font-size: 14px;
    width: 100%;
    padding-bottom: 20px;
}

.signin_footer p {
    margin: 6px 0;
    width: fit-content;
}

.signin_footer a {
    color: #888;
    transition: .3s;
    text-decoration: none;
}

.signin_footer a:hover {
    color: #53138D;
}

@media screen and (max-width:700px) {
    .StyledContent {
        min-height: 110vh !important;
    }
}

  @media screen and (max-width:700px) {
    .sign_up {
        height: 100vh !important;
        padding: 10px 40px !important;
    }
  }