#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid var(--primary-color);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid var(--primary-color);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

.pagination__wrap button[disabled] {
  cursor: no-drop;
}

@media screen and (max-width: 576px) {
  .pagination_button_container .btn.prev_next_btn {
    min-width: 35px !important;
    min-height: 35px !important;
    max-width: 35px !important;
    max-width: 35px !important;
    padding: 0 !important;
  }
  .pagination_button_container .btn.prev_next_btn i {
    font-size: 12px;
  }
  .pagination__wrap .page__count {
    text-align: center !important;
    width: fit-content !important;
    margin: auto !important;
    padding-bottom: 10px;
  }
  .pagination_button_container button.inserted_btn {
    min-width: 30px !important;
    min-height: 30px !important;
    max-width: 30px !important;
    max-width: 30px !important;
    padding: 0 !important;
  }
}
