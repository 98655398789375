

form {
    width: 100% !important;
}

* {
    font-family: 'Roboto', sans-serif !important;

}

.card {
    background: #FFFFFF !important;
    /* gray/200 */
    
    border: 1px solid #E5E7EB !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
}
.d_address h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 27px */


    /* gray/900 */

    color: #111827;
}
.modal_bg {
    background: #FFFFFF;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
border-radius: 8px;
}
.add_to_cart_content p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify !important;
    /* or 143% */

    text-align: center;

    /* gray/500 */

    color: #6B7280;
}
.add_to_cart_content .buttons .cancel {
    /* padding: 9px 17px; */

    height: 38px;

    /* white */

    background: #FFFFFF;
    /* gray/300 */

    border: 1px solid #D1D5DB;
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}
.add_to_cart_content .buttons button {
    display: inline-block;
    width: 49%;
}
.add_to_cart_content .buttons .go_to {
    /* padding: 9px 17px; */

    height: 38px;

    /* white */

    background: #53138D;
    /* shadow/sm */
    
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 0 !important;
    color: #FFFFFF;
}
.input_bg {
    background-color: white !important;
}
.sub_total h5 {
    margin-top: 10px !important;
    font-weight: bold;
}
.reduce_icons {
    width: 12% !important;
}
.delete_txt {
    font-size: 11px;
    padding-left: 5px;
    color: red;
}
.sub_total form {
    margin-top:10px !important;


}
label {
    font-size: 14px;
}
h5 {
    font-size: 16px;
}
body {
    /* background-color: #fff !important; */
}

img {
    display: inline !important;
}
.numbers {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.numbers div span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #53138D !important;
    /* or 36px */


    /* blue/600 */

    color: #53138D;
}
div.percent {
    background: #D1FAE5;
border-radius: 12px;
padding: 2px 10px;
display: inline-block;

}
div.percent small {
    font-size: 10px;
}
.wallet {
    background: linear-gradient(45deg, #8C2D91 0%, #53138D 100%);
box-shadow: 7.87342px 9.84177px 15.7468px rgba(0, 0, 0, 0.05);
border-radius: 19.6835px;
padding: 13px;
color: #FFFFFF;
}
.acc_number {
    font-weight: 600;
font-size: 13px;
line-height: 22px;
/* identical to box height */


/* Base/White */

color: #FFFFFF;
}
.table {
    /* color: #6B7280 !important; */
    width: 100% !important;

}
.thead  th {
    border-bottom: 1px solid #EAECF0 !important;
    border-top: 1px solid #EAECF0 !important;
    color: #53138D !important;
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 16px !important;
    padding-left: 23px !important;
    padding-right: 9px !important;
}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */
    
    
    /* gray/500 */
    
    color: #6B7280;
    padding-left: 23px;
    padding-right: 9px;
    
}

.tr {
    height: 77px !important;
    border-bottom: 1px solid #EAECF0;

}

.hold_input {
    position: relative !important;

}

.hold_input img {
    position: absolute;
    top: 24%;
    left: 25px !important;
}
.hold_input input {
    background: #FFFFFF !important;
    /* Gray/300 */

    border: 1px solid #D0D5DD !important;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
}

.export_btn {
    height: 40px;

    /* blue/600 */
    /* blue/600 */

    border: 1px solid #53138D;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Base/White */

    color: #53138D;
    padding-top: 0;


}
.filters button {
    padding: 5px 16px;
    gap: 8px;
    
    width: 108px;
    height: 37px;
/* White */

background: #FFFFFF;
/* Gray/300 */

border: 1px solid #D0D5DD;
/* Shadow/xs */

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 4px;
}
.export_btn:hover {
    color: #FFFFFF;
    font-weight: bold;
}
.abandoned {
    padding: 2px 8px;

    width: 78px;
    height: 22px;

    /* Gray/100 */

    background: #F2F4F7;
    border-radius: 16px;
}
.customer {
    padding: 2px 8px;

    width: 69px;
    height: 22px;

    /* Success/50 */

    background: #ECFDF3;
    border-radius: 16px;
}

.pagination_button_container button {
    background: #FFFFFF;
    
    border: 1px solid #D0D5DD;
    
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
}

.quantity_btn {
    color: #4D8FFF; 
    padding: 5px; 
    line-height: 0; 
    border-radius: 50%; 
    background: #EAF3FF; 
    width: 30px; 
    height: 30px;  
    display: flex; 
    justify-content: center; 
    align-items: center; 
    box-shadow: 0 3px 5px lightgray
}

.nothing_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25em;
    max-width: 100%;
    text-align: center;
    padding: 2em;
    margin: auto;
}
.nothing_detail_img {
    width: fit-content;
    margin: auto;
}
.nothing_detail_img img {
    width: 100px;
    max-width: 100%;
}
.nothing_wrapper h1 {
    margin-bottom: 0;
    font-weight: bolder;
    font-size: 1.5em;
}
.nothing_wrapper p {
    margin-top: 15px;
    color: #777;
}
.nothing_wrapper span {
    display: block;
    background-color: transparent;
    margin-top: 40px;
}
.nothing_wrapper span button {
    color: white;
    /* background: blue; */
    border-radius: 8px;
    padding: 10px 20px;
    text-decoration: none;
    white-space: nowrap;
}

@media only screen and (max-width : 480px) {
    .hold_input img {
        position: absolute;
        top: 28%;
        left: 13%;
    }
    .tr p {
        font-size: 10px !important;
    }
    .action_button {
        display: inline-block;
        margin-left: 10px !important;

    }

    .sub_total {
        position: relative !important;
    }
}