* {
  font-family: 'Roboto', sans-serif !important;
}

.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}
body {
  font-family: 'Roboto', sans-serif !important;
}

form {
  width: 100% !important;
}
.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: var(--primary-color) !important;
  /* or 36px */

  /* blue/600 */

  color: var(--primary-color);
}
.numbers div small {
  font-style: normal;

  font-size: 11px;
  color: #6b7280;
}
.wallet_actions .wallet_button .transfer {
  /* width: 100%; */
  width: 130px;
  height: 44px;

  /* white */

  background: #ffffff;
  /* gray/700 */

  border: 1px solid #374151;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* gray/700 */

  color: #374151;
  transition: all 0.5s;
}
.top_up_button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.top_up_button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.top_up_button:hover span {
  padding-right: 25px;
}

.top_up_button:hover span:after {
  opacity: 1;
  right: 0;
}

/*  */
.transfer span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.transfer span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.transfer:hover span {
  padding-right: 25px;
}

.transfer:hover span:after {
  opacity: 1;
  right: 0;
}

.wallet_actions .wallet_button .transfer:hover {
  border: 1px solid var(--primary-color) !important;
  /* color: #FFFFFF; */
}

.pagination_button_container button {
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}
.wallet_actions .wallet_button .top_up_button {
  transition: all 0.5s;

  background: var(--primary-color) !important;

  border: 1px solid var(--primary-color) !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  padding: 10px 16px;
  width: 130px;
  height: 44px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}

div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}
div.percent2 {
  background: #fee2e2;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  color: #991b1b !important;
  justify-content: ' space-around';
}
.customized_row small {
  font-weight: bold;
}
div.percent small {
  font-size: 10px;
}
div.percent2 small {
  font-size: 10px;
  color: #991b1b !important;
}

.wallet {
  width: 100% !important;
  position: relative !important;
  height: 284px;
}

.wallet .content {
  padding: 32px 41px;
  color: #ffffff;
  position: relative !important;
  z-index: 31 !important;
  margin: auto;
}

.wallet .img {
  width: 100% !important;

  position: absolute !important;
  z-index: 5 !important;
  margin: 10px !important;
  height: 224px;
}

.wallet .img3 {
  height: 275px !important;
}

.wallet .content .sub_text {
  color: #bfdbfe;
  font-size: 10px;
  margin-bottom: 10px;
}

.darkcardbg {
  position: absolute !important;
  margin: 2px 10px !important;
  height: 224px;
  width: 75% !important;
  background-color: #344054;
  bottom: 0.6rem;
  left: 50%;
  transform: translateX(-53%);
  margin: 0 auto !important;
}

.wallet .content .content_body {
  color: #bfdbfe;
  font-size: 10px;
  margin-top: 35px;
}
.wallet .content .content_body .bank_info .name {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 1px;
}
.wallet .content .content_body .bank_info img {
  height: 30px;
}
.analytics {
  margin-top: 20px !important;
}
.analytics .customized_row {
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-column-gap: 30px;
}
.analytics .customized_row1 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 15px;
}
.wallet .content .content_body .bank_info {
  display: flex;
  justify-content: space-between;
}
.wallet .content .content_body .account_number {
  font-weight: 400;
  font-size: 15.7468px;
  font-weight: 600;

  color: #ffffff;
  margin-bottom: 10px;
}
.wallet .footer {
  text-align: right;
}
.wallet .footer div p {
  font-size: 14px;
  color: var(--primary-color);
  font-style: normal;
  font-weight: 500;
}
.wallet .footer div p span {
  cursor: pointer;
}

.wallet .content .content_header {
  display: flex;
  justify-content: space-between;
}
.wallet .footer {
  /* position:absolute;
    bottom:0; */
  border-top: 1px solid #e5e7eb;
  width: 100%;
  height: 34px;
  padding: 4px;
}

.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}
.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}
.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: #53138d !important;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
  padding-left: 0 !important;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}
.export_btn {
  height: 40px;
  width: 130px;

  /* blue/600 */

  background: var(--primary-color);
  /* blue/600 */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: #ffffff;
  padding-top: 0;
}
.export_btn:hover {
  color: #ffffff;
  font-weight: bold;
}
.thead_tr th {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: var(--text-primary) !important;
  /* identical to box height, or 21px */

  /* gray/500 */
}
.thead_tr th:first-child,
.thead_tr th:first-child {
  padding-left: 20px !important;

  /* gray/500 */

  color: #6b7280;
}
.tr td:first-child,
.tr th:first-child {
  padding-left: 20px !important;
}
.thead_tr {
  height: 55px !important;
}
.tr td {
  color: #6b7280;
  padding: 0 1rem;
}
.card2 {
  background: #ffffff !important;

  border: 1.12465px solid #e5e7eb;
  box-shadow: 0px 1.12465px 3.37394px rgba(16, 24, 40, 0.1),
    0px 1.12465px 2.24929px rgba(16, 24, 40, 0.06) !important;
  border-radius: 8.99717px !important;
}
.modal_content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  color: #1f2937;
}
.modal_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
}
.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
  height: 30px !important;
}

.modal_content label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */

  color: #344054;
}
.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
.pending {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffedd5;
  color: #c2410c;
  border-radius: 16px;
}
.customer {
  padding: 2px 8px;
  width: 69px;
  height: 22px;
  background: #ecfdf3;
  color: #027a48;
  border-radius: 16px;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}

@media screen and (max-width: 860px) {
  .wallet {
    width: 100% !important;
  }
  .card2 {
    width: 40% !important;
  }
  .analytics .customized_row {
    display: block !important;
  }
}

@media screen and (max-width: 757px) {
  .wallet {
    width: 100% !important;
  }
  .card2 {
    width: 100% !important;
  }
  .analytics .customized_row1 {
    display: block !important;
  }
}

@media only screen and (max-width: 320px) {
  .wallet {
    background-size: cover !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallet {
    background-size: cover !important;
  }
}
