input[type='file'] {
    display: none;
}

.image_upload {
    /* background-color: lightgray; */
    padding: 2rem 1.5rem;
    width: 100%;
    display: grid;
    place-content: center;

}

.pic_icon {
    padding: 1rem;
    scale: 5;
    width: 100%;
    height: 100%;
    color:dimgray
}