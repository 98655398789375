.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

.css-hzdqck-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 14px !important;
}

form {
  width: 100% !important;
}

.customized_row {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 4px;
}

.number_cont {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.cancel_delete {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}

.card_control {
  background: #ffffff !important;
  /* gray/300 */

  border: 1px solid #d1d5db !important;
  /* /shadow/base */

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  vertical-align: middle !important;
}

.box_buttom_content {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.01em;

  color: #667085;
}

.btn_styles {
  padding: 10px 16px;
  gap: 8px;

  width: 130px;
  height: 44px;

  /* Chat Blue */

  background: #53138d;
  /* Chat Blue */

  border: 1px solid #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  color: white;
}

.box {
  border: 3px dashed #d1d5db;
  padding: 20px;
}

.div_control {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  /* gray/900 */

  color: #111827;
}

.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.delete_product {
  background: #dc2626;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 17px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* white */

  color: #ffffff;
}

.delete_product:hover {
  color: inherit;
}

img {
  display: inline !important;
}

.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #53138d !important;
  /* or 36px */

  /* blue/600 */

  color: #53138d;
}

div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 2px 10px;
  display: inline-block;
}

div.percent small {
  font-size: 10px;
}

.wallet {
  background: linear-gradient(45deg, #8c2d91 0%, #53138d 100%);
  box-shadow: 7.87342px 9.84177px 15.7468px rgba(0, 0, 0, 0.05);
  border-radius: 19.6835px;
  padding: 13px;
  color: #ffffff;
}

.pagination_button_container button {
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}

.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}

.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
  padding-left: 23px;
  padding-right: 9px;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}

.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
  padding-left: 23px;
  padding-right: 9px;
}

.hold_input {
  position: relative !important;
}

.hold_input img {
  position: absolute;
  top: 25%;
  left: 25px !important;
}

.hold_input input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.add_outlet {
  background: #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}

.modal_content label {
  font-weight: 500;
  font-size: 14px;
  color: #374151;
}

.modal_content .modal_btn {
  background: #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.add_outlet:hover {
  color: #fff;
  font-weight: bold;
}

.import {
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.export_btn {
  height: 40px;

  /* blue/600 */

  background: #53138d;
  /* blue/600 */

  border: 1px solid #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: #ffffff;
  padding-top: 0;
}

.card_control {
  background: #f9fafb !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  color: #6b7280 !important;
}

.notification {
  font-size: 14px;
}

.filters .button {
  padding: 5px 16px;
  gap: 8px;

  width: 108px;
  height: 37px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.export_btn:hover {
  color: #ffffff;
  font-weight: bold;
}

.abandoned {
  padding: 2px 8px;

  width: 78px;
  height: 22px;

  /* Gray/100 */

  background: #f2f4f7;
  border-radius: 16px;
}

.customer {
  padding: 2px 8px;

  width: 69px;
  height: 22px;

  /* Success/50 */

  background: #ecfdf3;
  border-radius: 16px;
}

@media screen and (max-width: 650px) {
  .marketing_attach_wrap {
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }
}

@media only screen and (max-width: 480px) {
  .hold_input img {
    position: absolute;
    top: 28%;
    left: 13%;
  }

  .tr p {
    font-size: 10px !important;
  }

  .action_button {
    display: inline-block;
    margin-left: 10px !important;
  }

  .sub_total {
    position: relative !important;
  }
}
