.modeCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

@media screen and (max-width: 700px) {
  .sub_sec {
    /* position:absolute; */
    bottom: 0;
  }
}
