.bg {
    width: 100% !important;
    height: 100% !important;

    padding-left: 80px !important;
    padding-top: 170px;
    background: linear-gradient(to right, #52138d9f, #8c2d9176), url('/public/assets/onboardingWithEffect.jpg');
    /* background-blend-mode: overlay; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

* {
    font-family: 'Roboto', sans-serif !important;

}


form {
    width: 100% !important;
}

.bg2 {
    width: 100% !important;
    height: 100% !important;

    padding-left: 20px !important;
    padding-top: 170px;
    background: linear-gradient(to right, #52138d9f, #8c2d9176), url('/public/assets/onboardingWithEffect.jpg');
    /* background-blend-mode: overlay; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

body {
    overflow-x: hidden;
    width: 100%;
}

img {
    display: inline-block !important;
}

.headerSection {
    margin-top: 10px !important;
}

.headerSection p {
    font-size: 14px !important;
    font-weight: lighter;
    margin-bottom: 0;
}

.sav {
    height: 100vh;
    overflow: hidden;
}

.bg h5,
.bg h4 {
    color: #FFFFFF;
    font-size: 40px;
    font-family: 'Roboto';
    font-weight: 500;
    font-style: normal;
    line-height: 120%;


}

.bg p {
    color: #EAECF0;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    font-style: normal;


}

ul li {
    color: #BFDFF8;
    /* line-height: 40px; */
    margin-top: 15px;
}

.features {
    margin-top: 50px !important;
}

/* li::before {
    content: url('/public/assets/check-circle.png') !important;
    display: inline-block;
} */

li img {
    /* display: inline-block; */
    width: 21px !important;
    float: left;
}

li small {
    display: inline-block;
    margin-left: 10px;
}

.content {
    top: 46%;
    left: 10%;
}

.icons {
    width: 152px;
    float: left;
}

.icon_n_text span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: #EAECF0;
    padding-left: 10px;
    margin-top: 10px;
    display: inline-block;
}

.syl {
    background-color: #f5f2fe;
    border-radius: 10px;
    margin-top: 15px;
    /* padding: 1px; */
}

.star {
    top: calc(100vh / 3.1);
}

.sign_up {
    padding: 20px 80px;
    height: 100vh;
    overflow-y: scroll;
}

textarea {
    resize: none !important;
}

form {
    margin-top: 30px;
}

.push_notification_icon {
    position: relative;
}

.push_notification_icon img {
    position: absolute;
    top: 64%;
    right: 2%;
}

.btn_custom_color {
    background-color: #53138D;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    transition: all 0.5s;

}

.btn_custom_color:hover {
    color: #FFFFFF;


}

.btn_custom_color span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.btn_custom_color span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.btn_custom_color:hover:enabled span {
    padding-right: 25px;
}

.btn_custom_color:hover:enabled span:after {
    opacity: 1;
    right: 0;
}

.buttons {
    margin-top: 20px;
}

.btn-g {
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;


}

form .form-control:focus {
    border-color: #0d6efd;
    box-shadow: none;
}

.main_img_container {
    width: 100px;
    height: 100px;
    background: #FFFFFF;
    padding-right: 0 !important;
    /* gray/100 */

    border: 1.3557px solid #F3F4F6;
    border-radius: 6.23457px;
    box-sizing: border-box !important;
    /* display: flex;
    align-items: center; */
    position: relative;
    z-index: 10;

}

.img_container {
    width: 100px;
    height: 100px;
    /* gray/100 */

    /* border: 1.3557px solid #F3F4F6; */
    border-radius: 6.23457px;
    box-sizing: border-box !important;
    /* display: flex;
    align-items: center; */
    position: relative;
    display: table-cell;
    vertical-align: middle;
    z-index: 10;

}

.avatar_container {
    /* gray/50 */
    width: 80px;
    margin: auto !important;

    height: 80px;

    background: #F9FAFB;
    overflow: hidden;
    /* gray/200 */

    border: 1.85697px solid #E5E7EB;
    border-radius: 78.9214px;
    margin-top: 20px;
    position: relative;
    z-index: 10;

}

.main_img_container .camera {
    /* background-image: url('/public/assets/icons/camera.png'); */
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10%;
    bottom: 10%;
    z-index: 100000;
    /* background-repeat: no-repeat; */
}

.kyc_cover {
    position: relative;
}

.download {
    background: #FFFFFF;
    height: 90px;
    box-shadow: 0px -4px 15px rgba(37, 99, 235, 0.08);
    /* width: 100%; */
    padding: 10px 0px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.download .content div {
    display: inline-block;
}

.content h5 {
    font-size: 30px;
    font-weight: 600;

    line-height: 150%;


}

.download .content div:last-child {
    margin-left: auto;
}

.kyc_content {
    position: relative;
    width: 100%;
    height: 93vh;
    overflow-x: hidden;
    padding: 0px 0px 120px 0px;
}

.kyc_content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 150%;
}

.kyc_content p {
    color: #374151;
    font-size: 14px;

}

.kyc_content .body_content {
    box-sizing: border-box;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 0px;
    padding-right: 10px;
    text-align: justify;
}

table,
thead,
tr,
tbody,
td {
    white-space: nowrap;
}

td:last-child,
td:last-of-type {
    margin: auto;
}

@media screen and (max-width:700px) {
    .sign_up {
        height: 100vh !important;
        padding: 10px 40px !important;
    }

    .kyc_content {
        padding: 0 15px 150px !important;
    }

    .content_control span {
        font-size: 10px;

    }

    .download {
        padding: 10px 20px 10px 30px !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: center !important;
    }

    .kyc_content .body_content {
        padding-right: 0;
    }
}