.Breadcrumb {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 1.5rem 1rem;
  box-shadow: 0px 0px 1px lightgray;
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.btnBox {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.crumbBtn {
  background-color: var(--primary-color);
  padding: 0.3rem 0.5rem;
  color: #fff;
  border: none;
  outline: none;
}

.crumbBtn:disabled {
  cursor: not-allowed;
}
