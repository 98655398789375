
.card {
    background: #FFFFFF !important;
    /* gray/200 */
    
    border: 1px solid #E5E7EB !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
}

* {
    font-family: 'Roboto', sans-serif !important;

}


form {
    width: 100% !important;
}

.customized_row {
    display: grid;
    grid-template-columns: 1fr 9fr;
    grid-column-gap: 4px;

}
.modal_bg {
    background: #FFFFFF;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
border-radius: 8px;
}
.add_to_cart_content p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* text-align: justify !important; */
    /* or 143% */

    text-align: center;

    /* gray/500 */

    color: #6B7280;
}
.add_to_cart_content .buttons .cancel {
    /* padding: 9px 17px; */

    height: 38px;

    /* white */

    background: #FFFFFF;
    /* gray/300 */

    border: 1px solid #D1D5DB;
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}
.add_to_cart_content .buttons button {
    display: inline-block;
    width: 49%;
}
.go_to {
    /* padding: 9px 17px; */

    height: 38px;

    /* white */

    background: #53138D;
    /* shadow/sm */
    
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    border: 0 !important;
    color: #FFFFFF;
    font-size: 18px;
}
.cancel_delete {
    background: #FFFFFF;
    /* gray/300 */

    border: 1px solid #D1D5DB;
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    padding: 9px 17px;
    color: #374151;
}
.cancel_delete:hover {
    background-color: inherit !important;
    color: inherit !important;
}
.delete_product {
    background: #DC2626;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 9px 17px;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* white */

    color: #FFFFFF;


}
.delete_product:hover {
    color: inherit;
}
img {
    display: inline !important;
}
.numbers {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.numbers div span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #53138D !important;
    /* or 36px */


    /* blue/600 */

    color: #53138D;
}
div.percent {
    background: #D1FAE5;
    border-radius: 12px;
    padding: 0px 10px;
    display: inline-block;
    width: '67px';
    height: '25px';
    border-radius: '12px';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    justify-content:' space-around';

}
div.percent2 {
    background: #FEE2E2;
    border-radius: 12px;
    padding: 0px 10px;
    display: inline-block;
    width: '67px';
    height: '25px';
    border-radius: '12px';
    display: 'flex';
    flex-direction: 'row';
    align-items: 'center';
    color: #991B1B !important;
    justify-content:' space-around';

}

div.percent small {
    font-size: 10px;
}
.wallet {
    background: linear-gradient(45deg, #8C2D91 0%, #53138D 100%);
box-shadow: 7.87342px 9.84177px 15.7468px rgba(0, 0, 0, 0.05);
border-radius: 19.6835px;
padding: 13px;
color: #FFFFFF;
}
.pagination_button_container button {
    background: #FFFFFF;
    
    border: 1px solid #D0D5DD;
    
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
}

.acc_number {
    font-weight: 600;
font-size: 13px;
line-height: 22px;
/* identical to box height */


/* Base/White */

color: #FFFFFF;
}

.thead  th {
    color: #53138D !important;
    border-bottom: 1px solid #EAECF0 !important ;
    font-weight: bold;
    font-size: 16px;
    padding: 16px;
    padding-left: 23px;
    padding-right: 9px;

}

.tr {
    height: 90px !important;
    border-bottom: 1px solid #EAECF0;
    
}
.thead tr {
    height: 44px;

}
.tr td{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 150%; */
    padding: 1px;

    /* identical to box height, or 21px */
    
    
    /* gray/500 */
    
    color: #6B7280;
    border-bottom: 1px solid #EAECF0;
    padding-left: 23px;
    padding-right: 9px;
}
  
  @media (max-width: 767px) {
    .responsive_table th,
    .responsive_table td {
      padding: 4px;
    }
  }
  
  @media (max-width: 480px) {
    .table-container {
      overflow-x: scroll;
    }
  }

.hold_input {
    position: relative !important;

}

.hold_input img {
    position: absolute;
    top: 24%;
    left: 25px !important;
}
.hold_input input {
    background: #FFFFFF !important;
    /* Gray/300 */

    border: 1px solid #D0D5DD !important;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
}
.add_outlet {
    background: #53138D;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #FFFFFF;
}
.modal_content {
    width: 100% !important;
}
.modal_content .modal_header {
    display: flex;
    justify-content: space-between;

}
.modal_content label {
    font-weight: 500;
    font-size: 14px;
    color: #374151;
}
.modal_content .modal_btn {
    background: #53138D;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #FFFFFF;

}
.add_outlet:hover {
    color: #fff;
    font-weight: bold;
}
.import {
    background: #FFFFFF;
/* Gray/300 */

border: 1px solid #D0D5DD;
/* Shadow/xs */

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 4px;
}

.export_btn {
    height: 40px;

    /* blue/600 */

    background: #53138D;
    /* blue/600 */

    border: 1px solid #53138D;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Base/White */

    color: #FFFFFF;
    padding-top: 0;


}
.export_btn1 {
    height: 40px;

    /* blue/600 */

    background: #FFFFFF;
    /* blue/600 */

    border: 1px solid #D1D5DB;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Base/White */

    color: #8C2D91;
    font-weight: bold;
    padding-top: 0;


}
.filters button {
padding: 5px 16px;
gap: 8px;

width: 108px;
height: 37px;

/* White */

background: #FFFFFF;
/* Gray/300 */

border: 1px solid #D0D5DD;
/* Shadow/xs */

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 4px;
}
.export_btn:hover {
    color: #FFFFFF;
    font-weight: bold;


}
.abandoned {
    padding: 2px 8px;

    width: 78px;
    height: 22px;

    /* Gray/100 */

    background: #F2F4F7;
    border-radius: 16px;
}
.customer {
    padding: 2px 8px;

    width: 69px;
    height: 22px;

    /* Success/50 */

    background: #ECFDF3;
    border-radius: 16px;
}

.myMapRow {
    justify-content: space-between; 
    align-items: flex-start; 
    position: absolute; 
    top: 10px; 
    left: 0; 
    right: 20em; 
    z-index: 999; 
    max-width: 100%;
}
.myMapRow::-webkit-scrollbar-track {
    background-color: unset !important;
}

/* table, thead, tr, tbody, td {
    white-space: nowrap;
} */

td:last-child, td:last-of-type {
    margin: auto;
}

@media screen and (max-width: 650px) {
    .myMapRow {
        flex-direction: column-reverse;
        gap: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .hold_input img {
        position: absolute;
        top: 28%;
        left: 13% ;
    }
    .tr p {
        font-size: 10px !important;
    }
    .action_button {
        display: inline-block;
        margin-left: 10px !important;

    }

    .sub_total {
        position: relative !important;
    }
}