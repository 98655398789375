.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}

.small {
  color: var(--primary-color) !important;
}

.card small {
  font-weight: bold;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

body {
  font-family: 'Roboto', sans-serif !important;
}

form {
  width: 100% !important;
}

.export_btn {
  height: 40px;

  /* blue/600 */

  background: #ffffff;
  /* blue/600 */

  border: 1px solid #8c2d91;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: #8c2d91;
  font-weight: bold;
  padding-top: 0;
}

.cover_products .customized_row {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: 5fr 5fr;
  grid-column-gap: 30px;
  /* height: 600px; */
  overflow-y: scroll;
}

.cover_products .customized_row1 {
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-column-gap: 12px;
}

.numbers {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.buy_now {
  padding-left: 5px;
  padding-right: 5px;
}

.buy_now button {
  width: 100% !important;

  /* white */

  background: #ffffff;
  /* blue/600 */

  border: 1px solid #53138d;
  color: #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  transition: 0.4s ease-in;
}

.buy_now button:hover {
  background-color: #53138d;
  color: white;
  font-weight: bold;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: var(--secondary-color) !important;
  /* or 36px */

  /* blue/600 */

  color: #53138d;
}

.numbers div small {
  font-style: normal;

  font-size: 11px;
  color: #6b7280;
}

.abandoned {
  padding: 2px 8px;

  width: 78px;
  height: 22px;

  /* Gray/100 */

  background: #f2f4f7;
  color: #344054;
  border-radius: 16px;
}
.pending {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffedd5;
  color: #c2410c;
  border-radius: 16px;
}

.refunded {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  /* Gray/100 */
  background: var(--primary-color);
  color: #fff;
  border-radius: 16px;
}

.abandoned {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffe3d5;
  color: var(--text-primary);
  border-radius: 16px;
}
.customer {
  padding: 2px 8px;
  width: 69px;
  height: 22px;
  background: #ecfdf3;
  color: #027a48;
  border-radius: 16px;
}

.btn_controls .confirmed {
  /* blue/50 */

  background: #eff6ff;
  /* blue/600 */

  border: 0.5px solid #53138d;
  color: #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.btn_controls .confirmed_disabled {
  background: #f9fafb;
  /* gray/400 */
  color: #9ca3af;

  border: 0.5px solid #9ca3af;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.btn_controls .confirm {
  background: #53138d;
  /* blue/600 */

  border: 1px solid #53138d;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
}

div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}

div.percent2 {
  background: #fee2e2;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  color: #991b1b !important;
  justify-content: ' space-around';
}

div.percent small {
  font-size: 10px;
}

div.percent2 small {
  font-size: 10px;
  color: #991b1b !important;
}

.wallet {
  width: 100% !important;
  position: relative !important;
}

.wallet .content {
  padding: 32px 41px;
  color: #ffffff;
  position: relative !important;
  z-index: 60 !important;
  margin: 0 auto;
}

.discount_banner {
  background: #d97706;
  /* amber/700 */

  border: 1px solid #b45309;
  color: #eff6ff;
  display: inline-block !important;
  width: 120px;
  text-align: center;
  position: absolute;
  right: -3%;
  transform: rotate(35deg);
  top: 5%;
  font-size: 13px;
}

.wallet .img {
  width: 95% !important;
  position: absolute !important;
  z-index: 5 !important;
  margin: 2px 10px !important;
  height: 234px;
}

.wallet .img1 {
  height: 215px !important;
}

.wallet .img3 {
  height: 275px !important;
}

.darkcardbg {
  position: absolute !important;
  margin: 2px 10px !important;
  height: 224px;
  width: 75% !important;
  background-color: #344054;
  bottom: 2.3rem;
  left: 50%;
  transform: translateX(-53%);
  margin: 0 auto !important;
}

.wallet .img1 {
  width: 100% !important;
  position: absolute !important;
  z-index: 0.1 !important;
  margin: 2px 10px !important;
  height: 264px;
}

.wallet .content .sub_text {
  color: #bfdbfe;
  font-size: 10px;
  margin-bottom: 10px;
}

.wallet .content .content_body {
  color: #bfdbfe;
  font-size: 10px;
  margin-top: 35px;
}
.content_body1 {
  margin-top: 77px !important;
}

.wallet .content .content_body2 {
  height: 65px;
}

.wallet .content .content_body .bank_info .name {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 1px;
}

.wallet .content .content_body .bank_info img {
  height: 30px;
}

.customized_row {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 4px;
}

.cancel_delete {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}

.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.delete_product {
  background: #dc2626;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 17px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* white */

  color: #ffffff;
}

.delete_product:hover {
  color: inherit;
}

.analytics .customized_row {
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-column-gap: 30px;
}

.analytics .customized_row1 {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-column-gap: 10px;
}

.product_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* or 24px */

  /* gray/700 */

  color: #374151;
}

.product_txt h6 {
  font-weight: 700;
}

.product_price {
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 24px */
  margin-top: 10px;

  /* blue/600 */

  color: #53138d;
}

.price_off {
  padding: 1.13725px 5.68627px;

  width: 29.37px;
  height: 14.27px;

  /* green/100 */

  background: #d1fae5;
  border-radius: 6.82353px;
  font-weight: 500;
  font-size: 8px;
  line-height: 150%;
  /* or 21px */

  text-align: right;

  /* green/800 */

  color: #065f46;
}

.price_strike {
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-decoration-line: line-through;

  /* gray/400 */

  color: #9ca3af;
}

.product_price p {
  display: inline-block;
  margin-bottom: 0 !important;
}

.wallet .content .content_body .bank_info {
  display: flex;
  justify-content: space-between;
}

.wallet .content .content_body .account_number {
  font-weight: 400;
  font-size: 15.7468px;
  font-weight: 600;

  color: #ffffff;
  margin-bottom: 10px;
}

.wallet .footer {
  text-align: right;
}

.wallet .footer div p {
  font-size: 16px;
  color: #53138d;
  font-style: normal;
  font-weight: bold;
}

.wallet .footer div p span {
  cursor: pointer;
}

.wrapper .header h4 {
  font-weight: bold !important;
}

.wrapper .header p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* gray/600 */

  color: #4b5563;
}

.wallet .content .content_header {
  display: flex;
  justify-content: space-between;
}

.wallet .footer {
  /* position:absolute;
    bottom:0; */
  border-top: 1px solid #e5e7eb;
  width: 100%;
  height: 40px;
  padding: 4px;
}

.wallet .footer2 {
  height: auto;
}

.retailer_img_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.retailer_div_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.retailer_top_banner {
  background: linear-gradient(
    180deg,
    #286bc9 -29.63%,
    rgba(40, 107, 201, 0.5) 151.85%
  );
  border-radius: 5px;
  min-height: 150px;
}

.retainer_top_banner_price {
  color: #ffffff;
  margin-bottom: 5px !important;
}

.retainer_top_button {
  background-color: #ffffff;
  font-size: 13px;
  color: #53138d;
  font-weight: bold;
  width: 80%;
  padding: 3px;
  border: none;
  border-radius: 5px;
}

.retailer_product_img {
  height: 120px;
  width: 100%;
  height: 140px;
  background: linear-gradient(
    0deg,
    rgba(228, 228, 228, 0.2),
    rgba(228, 228, 228, 0.2)
  );
  /* background-color: ; */
}

.retailer_modal_product_img {
  height: 120px;
  width: 40%;
  height: 140px;
  background: linear-gradient(
    0deg,
    rgb(184 180 180 / 20%),
    rgb(192 187 187 / 20%)
  );
  /* background: linear-gradient(0deg, rgba(228, 228, 228, 0.2), rgba(228, 228, 228, 0.2)); */
  /* background-color: ; */
}

.retailer_modal_buynow_tag {
  font-weight: bold;
  font-size: 13px !important;
}

.retailer_modal_buynow_value {
  margin-left: 20px;
  font-size: 13px !important;
}

.retailer_modal_buynow_desc {
  font-size: 12px !important;
}

.add_to_cart_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: justify !important;
  /* or 143% */

  text-align: center;

  /* gray/500 */

  color: #6b7280;
}

.modal_bg {
  background: #ffffff;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.add_to_cart_content .buttons .cancel {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.add_to_cart_content .buttons button {
  display: inline-block;
  width: 49%;
}

.go_to {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: #53138d;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0 !important;
  color: #ffffff;
  font-size: 18px;
}

.add_to_cart_content .buttons .go_to {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: #53138d;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0 !important;
  color: #ffffff;
}

.retailer_modal_buyNow_img {
  height: 220px;
  border-radius: 10px;
  width: 100%;
  height: 140px;
  background: linear-gradient(
    0deg,
    rgb(184 180 180 / 20%),
    rgb(192 187 187 / 20%)
  );
  /* background: linear-gradient(0deg, rgba(228, 228, 228, 0.2), rgba(228, 228, 228, 0.2)); */
  /* background-color: ; */
}

.retailer_product_name {
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 0px !important;
}

.retailer_product_info_wrapper {
  margin-top: 2px !important;
}

.retailer_product_info {
  font-size: 12px;
  color: #6b7280;
  font-weight: 500;
}

.pointer_cursor {
  cursor: pointer;
}

.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}

.modal_content .modal_btn {
  background: #53138d;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.modal_product_title {
  font-weight: 600;
  font-size: 11px;
}

.retailer_product_info2 {
  display: flex;
  justify-content: right;
  color: #6b7280;
  font-size: 12px;
  font-weight: 500;
}

.remove_space {
  margin-top: 0px !important;
}

.retailer_product_edit {
  border-right: 1px solid #eaecf0;
  width: 50% !important;
  padding-top: 3px;
  display: inline-block;
  color: #53138d;
}

.retailer_product_delete {
  display: inline-block;
  width: 50% !important;
  text-align: center;
  color: #ef4444;
}

.retailer_product_edit_delete {
  border-top: 1px solid #eaecf0;
  width: 100%;
  margin: 10px 15px 0px 15px;
  text-align: center;
}

.retailer_product_card {
  border: solid 1px #eaecf0;
  height: auto;
  border-radius: 5px;
  margin-top: 0px;
}

.retainer_top_crossed_amount {
  color: #bfdbfe;
  font-size: 10px;
  margin-bottom: 10px;
  text-decoration: line-through;
}

.retailer_product_image {
  height: 120px;
  margin: 5% 26%;
}

.retailer_modal_buynow_image {
  height: 120px;
  margin: 2% 40%;
}

.product_title {
  color: #fff;
  font-size: 13px;
  margin-bottom: 0rem !important;
}

.product_promo {
  color: #f5977a;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product_promo_tag {
  background-color: #484848;
  width: 50px;
  height: 50px;
  left: 563px;
  top: 219px;
  line-height: 10px;
  border-radius: 50%;
  color: white;
  text-align: center;
  padding: 10px;
}

.product_promo_tag_content {
  margin: 6px 6px 0 3px;
  text-align: center;
  margin-bottom: 0px !important;
}

.product_promo_tag_content2 {
  margin-top: 0px !important;
  line-height: 23px;
  font-size: 10px;
}

.margin_left {
  margin-left: 20% !important;
}

/* .promo > p{
    color: #FFFFFF;
    font-size: 15px !important;
    line-height: 10px;
}
.promo > i{
    font-size: 12px !important;
    color: #ffffff;
} */
.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}

.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}

.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  font-size: 16px;
  padding: 16px !important;
  padding-left: 23px !important;
  padding-right: 9px !important;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}

.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
  padding-left: 13px;
  padding-right: 9px;
}

.abandoned {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  /* Gray/100 */
  background: #e83e20;
  color: #fff;
  border-radius: 16px;
}

.refunded {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  /* Gray/100 */
  background: #53138d;
  color: #fff;
  border-radius: 16px;
}

.customer {
  padding: 2px 8px;
  width: 69px;
  height: 22px;
  background: #ecfdf3;
  border-radius: 16px;
  color: #10b981;
}
.pending {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #f9e9d9;
  border-radius: 16px;
  color: #d97706;
}
.sub_text_02 {
  color: #6b7280;
}

.banner {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  /* background-color: #075985; */
  border-radius: 10px;
}

.banner2 {
  margin-top: 20px;
  width: 100%;
  height: 150px;
  border-radius: 10px;
}

.banner_text {
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase !important;
  width: 55%;
  line-height: 35px;
  padding: 40px 30px 0 80px;
}

.banner_img1 {
  height: 150px;
  width: 100%;
}

.banner_text2 {
  margin: 40px 30px 0 25px;
  height: 70px;
  width: 55%;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}

@media screen and (max-width: 860px) {
  .wallet {
    width: 100% !important;
  }

  .card2 {
    width: 40% !important;
  }

  .analytics .customized_row {
    display: block !important;
  }

  .cover_products .customized_row {
    display: block !important;
  }
}

@media screen and (max-width: 777px) {
  .wallet {
    width: 100% !important;
  }

  .card2 {
    width: 100% !important;
  }

  .cover_products .customized_row1 {
    display: block !important;
  }

  .analytics .customized_row1 {
    display: block !important;
  }

  .retailer_top_banner {
    height: 250px;
  }

  .margin_left {
    margin-left: 0px !important;
  }

  .retainer_top_button {
    width: 100%;
  }

  .retainer_top_discounted_price_container {
    text-align: center;
  }
}

@media only screen and (max-width: 320px) {
  .wallet {
    background-size: cover !important;
  }
}

@media only screen and (max-width: 480px) {
  .action_button {
    display: inline-block;
    margin-left: 10px !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .wallet {
    background-size: cover !important;
  }

  .retailer_top_banner {
    height: 250px;
  }

  .margin_left {
    margin-left: 0px !important;
  }

  .retainer_top_button {
    width: 100%;
  }

  .retainer_top_discounted_price_container {
    text-align: center;
  }

  .retailer_product_img {
    height: 120px;
    width: 100%;
    padding-left: 10%;
  }

  .retailer_modal_product_img {
    height: 120px;
    width: 100%;
    padding-left: 10%;
  }

  .retailer_product_image {
    height: 120px;
    margin: 1% 26%;
  }

  .retailer_modal_buynow_image {
    height: 120px;
    margin: 4% 37%;
  }

  .light_color {
    color: #6b7280;
  }
}
