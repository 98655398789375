* {
    font-family: 'Roboto', sans-serif !important;

}

input[type='file'] {
    display: none;
}

.file_input_container {
    border: 3px dashed lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    cursor: pointer;
}

form {
    width: 100% !important;
}

.input_inner {
    display: grid;
    place-content: center;
    text-align: center;
    cursor: pointer;
}


.btn_styles {
    padding: 10px 16px;
    gap: 8px;
    width: 130px;
    height: 44px;
    background: #53138D;
    border: 1px solid #53138D;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: white;
}

.box_buttom_content {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #667085;
    text-align: center;
}
