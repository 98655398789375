* {
  font-family: 'Roboto', sans-serif !important;
}

.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}
.table_responsive {
  display: block;
  width: 100% !important;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  /* background-color: #fff !important; */
}

form {
  width: 100% !important;
}
.back_button {
  border: solid 1px grey;
  height: 35px;
  width: 40px;
  border-radius: 5px 0 0 5px;
  float: left;
  cursor: pointer;
}
.product_selected {
  /* width: 70px; */
  height: 24px;
  padding: 3px 4px 3px 3px;

  /* Base/White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* text-align: center; */

  /* Gray/700 */

  color: #344054;
  display: inline-block;
  margin-right: 4px;
}
.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}
.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
img {
  display: inline !important;
}
.sales_title {
  display: flex;
  align-items: center;
}
.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-color) !important;
  /* or 36px */

  /* blue/600 */

  color: var(--primary-color);
}
div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}

div.percent2 {
  background: #fee2e2;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  color: #991b1b !important;
  justify-content: ' space-around';
}
.wallet {
  background: linear-gradient(45deg, #8c2d91 0%, var(--primary-color) 100%);
  box-shadow: 7.87342px 9.84177px 15.7468px rgba(0, 0, 0, 0.05);
  border-radius: 19.6835px;
  padding: 13px;
  color: #ffffff;
}
.pagination_button_container button {
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}
.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}

.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 16px !important;
  padding-left: 23px !important;
  padding-right: 9px !important;

  text-wrap: wrap;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
  padding-left: 23px;
  padding-right: 9px;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
}
.hold_input {
  position: relative !important;
}

.hold_input img {
  position: absolute;
  top: 28%;
  left: 25px !important;
}
.hold_input input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.export_btn {
  height: 40px;

  /* blue/600 */

  background: var(--primary-color);
  /* blue/600 */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: #ffffff;
  font-weight: bold;
  padding-top: 0;
}

.export_btn:hover {
  color: #ffffff;
}

.filters button {
  padding: 5px 16px;
  gap: 8px;

  width: 108px;
  height: 37px;

  /* White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.abandoned {
  padding: 2px 8px;

  width: 78px;
  height: 22px;

  /* Gray/100 */

  background: #f2f4f7;
  border-radius: 16px;
}
.customer {
  padding: 2px 8px;

  width: 69px;
  height: 22px;

  /* Success/50 */

  background: #ecfdf3;
  border-radius: 16px;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}
@media screen and(max-width: 1280) {
  .hold_input img {
    position: absolute;
    top: 28%;
    left: 15%;
    display: none;
  }
}

.summarytitles {
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #1f2937;
}
.summaryValues {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #374151;
  margin-right: 30px;
}
.inline_block {
  display: inline-block !important;
}
.margin_top {
  margin-top: 150px;
}

@media only screen and (max-width: 480px) {
  .margin_top {
    margin-top: 20px;
  }
  .height {
    height: auto !important;
    /* display: none; */
  }
  .hold_input img {
    position: absolute;
    top: 28%;
    left: 13%;
  }
  .tr p {
    font-size: 10px !important;
  }
  .action_button {
    display: inline-block;
    margin-left: 10px !important;
  }

  .sub_total {
    position: relative !important;
  }

  .tr td {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    /* identical to box height, or 21px */

    /* gray/500 */

    color: #6b7280;
  }
  .container_checkout {
    /* height: 200px !important; */
  }
}
