* {
  font-family: 'Roboto', sans-serif !important;
}

form {
  width: 100% !important;
}
.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}

.customized_row {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 4px;
}
.table_section tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}
.table_section2 tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}
.table_section td,
.table_section th {
  vertical-align: middle;
  font-weight: lighter;
}

.card_table {
  /* padding: 15px !important; */
}

.card_table .header h3 {
  font-weight: 800;
  line-height: 1;
}
.card_table .header p {
  font-size: 14px;
}

.card_table .sub_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  left: 15px;
}

.card_table .sub_header input::placeholder {
  font-size: 14px;
}

.card_table .sub_header .edit-btn .btn {
  padding: 7px 20px !important;
  float: right;
}

.card_table .edit-btn .btn {
  font-size: 13px;
}

@media screen and (max-width: 410px) {
  .card_table .row p span {
    font-size: 10px;
  }
}
@media screen and (max-width: 570px) {
  .card_table .sub_header .edit-btn .btn {
    float: none !important;
    margin-bottom: 10px;
  }
}

.card_table .head_wrapper {
  border-bottom: 1px solid #ddd;
}
.card_table .footer_wrapper {
  border-top: 1px solid #ddd;
}
.card_table .show_numbers {
  display: flex;
  align-items: center;
  gap: 10px;
}
.card_table .number_cont {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.card_table .svg_div svg {
  transition: 0.3s;
  cursor: pointer;
}
.card_table .svg_div svg:hover {
  transform: scale(1.5);
}
.card_table .filter_btn,
.card_table .sortby_btn {
  width: fit-content;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 7px 15px;
  cursor: pointer;
}
.card_table .previous_btn {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 7px 15px;
  cursor: pointer;
  font-size: 14px;
}
.card_table .card_table {
  border-radius: 8px;
  overflow: hidden;
}
.card_table .table_section {
  overflow-x: scroll;
}
.card_table .table_section::-webkit-scrollbar,
.card_table .table_section::-webkit-scrollbar-track {
  appearance: none;
  display: none;
}

.card_table table tr,
table td {
  vertical-align: middle;
  white-space: nowrap;
}
.card_table table td span {
  padding: 0 7px;
  border-radius: 10px;
  color: RGB(63, 135, 85);
  background: RGBA(63, 135, 85, 0.2);
  font-weight: bold;
}
.card_table .button_b {
  position: relative;
  top: 0px;
}
.card_table .button_i {
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 0px;
}
.card_table button::after {
  display: none;
}
.icon_route {
  display: none;
}
.text_route {
  display: block;
}
@media screen and (max-width: 440px) {
  .icon_route {
    display: block;
  }
  .text_route {
    display: none;
  }
  .display_filter,
  .display_sortby {
    display: none;
  }
}
.cancel_delete {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}
.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}
.delete_product {
  background: #dc2626;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 17px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* white */

  color: #ffffff;
}
.delete_product:hover {
  color: inherit;
}
img {
  display: inline !important;
}
.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-color) !important;
  /* or 36px */

  /* blue/600 */

  color: var(--primary-color);
}
div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 2px 10px;
  display: inline-block;
}
div.percent small {
  font-size: 10px;
}
.wallet {
  background: linear-gradient(45deg, #6f2c81 0%, var(--primary-color) 100%);
  box-shadow: 7.87342px 9.84177px 15.7468px rgba(0, 0, 0, 0.05);
  border-radius: 19.6835px;
  padding: 13px;
  color: #ffffff;
}
.pagination_button_container button {
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}
.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}
.thead th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
  padding-left: 23px;
  padding-right: 9px;
}

thead tr th {
  border-bottom: 1px solid #eaecf0 !important;
  border-top: 1px solid #eaecf0 !important;
  color: var(--text-primary) !important;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
  padding-left: 23px;
  padding-right: 9px;
}

.tr {
  height: 77px !important;
  border-bottom: 1px solid #eaecf0;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
  padding-left: 23px;
  padding-right: 9px;
}
.hold_input {
  position: relative !important;
}

.hold_input img {
  position: absolute;
  top: 26%;
  left: 17px !important;
}
.hold_input input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}
.add_outlet {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}
.modal_content label {
  font-weight: 500;
  font-size: 14px;
  color: #374151;
}

.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
.add_outlet:hover {
  color: #fff;
  font-weight: bold;
}
.import {
  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.export_btn {
  height: 40px;

  /* blue/600 */
  /* blue/600 */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: var(--primary-color);
  padding-top: 0;
}

.export_btn:hover {
  color: var(--primary-color);
}
/* .filters  {
padding: 5px 16px;
gap: 8px;

width: 108px;
height: 37px;


background: #FFFFFF;

border: 1px solid #D0D5DD;

box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 4px;
} */

.abandoned {
  padding: 2px 8px;

  width: 78px;
  height: 22px;

  /* Gray/100 */

  background: #f2f4f7;
  border-radius: 16px;
}
.customer {
  padding: 2px 8px;

  width: 69px;
  height: 22px;

  /* Success/50 */

  background: #ecfdf3;
  border-radius: 16px;
}

.first_acc_opt {
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
}

.first_acc_opt_span {
  cursor: pointer;
  margin-left: 4px;
  padding: 4px 0 4px;
}

.acc_details_header {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.acc_details_span {
  white-space: nowrap;
  border: 1px solid #ccc !important;
  border-radius: 5px;
  display: block;
  width: fit-content;
  padding: 0px 7px;
}

.acc_details_box {
  vertical-align: middle;
  height: 13px;
  width: 13px;
  margin-right: 4px;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}

@media only screen and (max-width: 480px) {
  .hold_input img {
    position: absolute;
    top: 28%;
  }
  .tr p {
    font-size: 10px !important;
  }
  .action_button {
    display: inline-block;
    margin-left: 10px !important;
  }

  .sub_total {
    position: relative !important;
  }
}
