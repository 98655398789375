.modal_content h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    

    color: #1F2937;
}
.modal_content p {
    font-style: normal;
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 21px */


    /* gray/500 */

}
.modal_content .modal_header {
    display: flex;
    justify-content: space-between;

}

.modal_content label {
    
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    line-height: 20px;
    /* identical to box height, or 143% */


    /* Gray/700 */


}
.modal_content .modal_btn {
    background: #53138D;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #FFFFFF;

}

.modal_btn_outline{
    background-color: white !important;
    color: #53138D !important;
    border:solid 1px #53138D !important;
    text-decoration: underline;
}

.MuiBackdrop-root  {
 background: transparent !important;
}
