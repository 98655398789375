* {
  font-family: 'Roboto', sans-serif !important;
}
.card {
  background: #ffffff !important;
  /* gray/200 */

  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}
body {
  font-family: 'Roboto', sans-serif !important;
}

.dropdown_wrap {
  position: relative;
}

.alert {
  position: relative;
  padding: 0.2rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.product_dialog {
  z-index: 1;
  position: 'absolute';
  will-change: 'transform';
  transform: translate3d(-100px, -100px, -100px) translateY(-30px) !important;
}

.product_dialog_two {
  z-index: 1;
  position: 'absolute';
  will-change: 'transform';
  transform: translate3d(-100px, -100px, -100px) translateY(0px) !important;
}

.dropdown_item {
  padding: 5px !important;
}

.pending {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffedd5;
  color: #c2410c;
  border-radius: 16px;
}
.abandoned {
  padding: 2px 8px;
  width: 78px;
  height: 22px;
  background: #ffe3d5;
  color: red;
  border-radius: 16px;
}
.customer {
  padding: 2px 8px;
  width: 69px;
  height: 22px;
  background: #ecfdf3;
  color: #027a48;
  border-radius: 16px;
}

form {
  width: 100% !important;
}

.go_to {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: var(--primary-color);
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0 !important;
  color: #ffffff;
  font-size: 18px;
}

.add_to_cart_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* text-align: justify !important; */
  /* or 143% */

  text-align: center;

  /* gray/500 */

  color: #6b7280;
}
.add_to_cart_content .buttons .cancel {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.add_to_cart_content .buttons button {
  display: inline-block;
  width: 49%;
}
.modal_bg {
  background: #ffffff;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.discount_banner {
  background: #d97706;
  /* amber/700 */

  border: 1px solid #b45309;
  color: #eff6ff;
  display: inline-block !important;
  width: 120px;
  text-align: center;
  position: absolute;
  right: -3%;
  transform: rotate(35deg);
  top: 5%;
  font-size: 13px;
}
.add_to_cart_content .buttons .go_to {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: var(--primary-color);
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0 !important;
  color: #ffffff;
}
.cancel {
  /* padding: 9px 17px; */

  height: 38px;

  /* white */

  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.action_button {
  display: inline-block;
  margin-left: 10px !important;
}

.numbers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.one_of div {
  /* display: inline-block; */
}
.one_of div img {
  width: 18%;
}
.numbers div span {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  color: var(--primary-color) !important;
  /* or 36px */

  /* blue/600 */

  color: var(--primary-color);
}

.numbers div small {
  font-style: normal;

  font-size: 11px;
  color: #6b7280;
}
.duration_discount {
  width: 66%;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  height: 37px;
  /* display: flex; */
}
.check {
  width: 70% !important;
  padding-left: 8px;
}

.duration_discount div {
  display: inline-block;
  width: 25%;
  text-align: center;
  padding: 5px;
  color: #6b7280;
}

.times {
  font-size: 12px;
}
.discount_price_holder div {
  display: inline-block;
}
.discount_price_holder div:first-child {
  width: 90%;
}
.q_holder {
  width: 10%;
}
.modal_product_title {
  /* color: #6c757d!important; */
  font-weight: 600 !important;
  font-size: 13px !important;
}
.push_notification_icon {
  position: relative;
}
.push_notification_icon img {
  position: absolute;
  top: 64%;
  right: 2%;
}
.push_notification_icon .check_circle {
  position: absolute;
  top: 54%;
  right: 7%;
  /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(350deg) brightness(100%); */
}
.images_width img {
  width: 14%;
}
.delete_category {
  padding: 10px 18px;
  gap: 8px;

  height: 44px;

  /* red/600 */

  background: #ffffff;
  border: 1px solid #dc2626;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Base/White */

  color: #dc2626;
}
.cancel_delete_category {
  padding: 10px 27px;
  gap: 8px;

  height: 44px;

  /* red/600 */

  background: #ffffff;
  border: 1px solid var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Base/White */

  color: var(--primary-color);
}

.export_btn1 {
  height: 40px;

  /* blue/600 */

  background: #ffffff;
  /* blue/600 */

  border: 1px solid #d1d5db;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: var(--primary-color);
  font-weight: bold;
  padding-top: 0;
}

.delete_category:hover {
  color: #dc2626;
  font-weight: bold;
}
.product_actions .product_button .transfer {
  /* width: 100%; */
  width: 185px;
  height: 44px;

  /* white */

  background: #ffffff;
  /* gray/700 */

  border: 1px solid #374151;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* gray/700 */

  color: #374151;
  transition: all 0.5s;
}

.product_actions .product_button .transfer:hover {
  border: 1px solid var(--primary-color) !important;

  /* color: #FFFFFF; */
}

.product_actions .product_button .top_up_button {
  transition: all 0.5s;

  background: var(--primary-color) !important;

  border: 1px solid var(--primary-color) !important;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 4px !important;
  padding: 10px 18px;

  width: 185px;
  height: 44px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* White */

  color: #ffffff;
}
.top_up_button:hover {
  font-weight: bold !important;
}
.pagination_button_container button {
  background: #ffffff;

  border: 1px solid #d0d5dd;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}
.add_product_btn {
  padding: 5px 18px;

  /* height: 44px; */

  /* blue/600 */

  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Base/White */

  color: #ffffff;
}
.delete_product_btn {
  padding: 10px 18px;

  /* height: 44px; */

  /* blue/600 */

  background: #dc2626;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Base/White */
  color: #ffffff;
}
.cover_products .customized_row {
  display: grid;
  grid-template-columns: 5fr 5fr;
  grid-template-rows: 5fr 5fr;
  grid-column-gap: 30px;
  /* height: 600px; */
  overflow-y: scroll;
}
.cover_products .customized_row1 {
  display: grid;
  grid-template-columns: 3fr 5fr;
  grid-column-gap: 8px;
}

.product_price {
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 24px */
  margin-top: 10px;

  /* blue/600 */

  color: var(--primary-color);
}
.product_price p {
  display: inline-block;
  margin-bottom: 0 !important;
}
.price_off {
  padding: 1.13725px 5.68627px;

  width: 29.37px;
  height: 14.27px;

  /* green/100 */

  background: #d1fae5;
  border-radius: 6.82353px;
  font-weight: 500;
  font-size: 8px;
  line-height: 150%;
  /* or 21px */

  text-align: right;

  /* green/800 */

  color: #065f46;
}
.price_strike {
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-decoration-line: line-through;

  /* gray/400 */

  color: #9ca3af;
}
.product_txt {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* or 24px */

  /* gray/700 */

  color: #374151;
}
.product_txt h6 {
  font-weight: 700;
}
.add_product_btn:hover {
  color: #ffffff;
}
.export_btn {
  height: 40px;

  /* blue/600 */

  /* blue/600 */

  border: 1px solid var(--primary-color);
  /* Shadow/xs */
  background-color: var(--primary-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Base/White */

  color: #ffffff;
  padding-top: 0;
}

.export_btn:hover {
  color: #ffffff;
}

div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}
div.percent2 {
  background: #fee2e2;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  color: #991b1b !important;
  justify-content: ' space-around';
}
div.percent small {
  font-size: 10px;
}
div.percent2 small {
  font-size: 10px;
  color: #991b1b !important;
}
.product {
  width: 100% !important;
  height: 100% !important;

  /* background-image:url('/public/assets/crc.png'); */

  /* background-repeat:no-repeat !important;

    border-radius:30px !important; */

  position: relative !important;
}
.product .content {
  padding: 32px 41px;
  color: #ffffff;
  position: relative !important;
  z-index: 31 !important;
}
.product .img {
  width: 100% !important;

  position: absolute !important;
  z-index: 0.1 !important;
  margin: 10px 10px !important;
  height: 224px;
}
.product .content .sub_text {
  color: #bfdbfe;
  font-size: 10px;
  margin-bottom: 10px;
}
.product .content .content_body {
  color: #bfdbfe;
  font-size: 10px;
  margin-top: 50px;
}
.product .content .content_body .bank_info .name {
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 1px;
}
.product .content .content_body .bank_info img {
  height: 30px;
}
.analytics {
  margin-top: 40px !important;
}

.product .content .content_body .bank_info {
  display: flex;
  justify-content: space-between;
}
.product .content .content_body .account_number {
  font-weight: 400;
  font-size: 15.7468px;
  font-weight: 600;

  color: #ffffff;
  margin-bottom: 10px;
}
.product .footer {
  text-align: right;
}
.product .footer div p {
  font-size: 14px;
  color: var(--primary-color);
  font-style: normal;
  font-weight: 500;
}
.product .footer div p span {
  cursor: pointer;
}

.product .content .content_header {
  display: flex;
  justify-content: space-between;
}
.product .footer {
  /* position:absolute;
    bottom:0; */
  border-top: 1px solid #e5e7eb;
  width: 100%;
  height: 34px;
  padding: 4px;
}

.acc_number {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height */

  /* Base/White */

  color: #ffffff;
}
.table {
  /* color: #6B7280 !important; */
  width: 100% !important;
}
.thead th {
  color: var(--text-primary) !important;
  border-bottom: 1px solid #eaecf0 !important ;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
  padding-left: 23px;
  padding-right: 9px;
}

.tr {
  height: 72px !important;
  border-bottom: 1px solid #eaecf0;
}
.thead tr {
  height: 44px;
}
.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 150%; */
  padding: 1px;

  /* identical to box height, or 21px */

  /* gray/500 */

  color: #6b7280;
  border-bottom: 1px solid #eaecf0;
  padding-left: 23px;
  padding-right: 9px;
}
.cancel_delete {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}
.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.cancel_delete {
  background: #ffffff;
  /* gray/300 */

  border: 1px solid #d1d5db;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}
.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.confirm_order {
  /* blue/600 */
  background: var(--primary-color);
  /* shadow/sm */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  color: white;
  width: 47%;
}
.confirm_order:hover {
  color: white;
}
.cancel:hover {
  color: #000000;
}
.delete_product {
  background: #dc2626;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 17px;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* white */
  color: #ffffff;
}
.delete_product:hover {
  color: #dc2626;
}
.view_action {
  width: 72px;
  height: 32px;

  /* blue/600 */

  background: var(--primary-color);
  box-shadow: 0px 0px 1px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 11.6364px;
  line-height: 150%;
  /* or 17px */

  /* Base/White */

  color: #ffffff;
}
.view_action:hover {
  color: #ffffff;
}

.delete_action {
  background: #f00;
  box-shadow: 0px 0px 1px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.add_action {
  background-color: transparent !important  ;
  color: var(--primary-color) !important;
  border: 1px solid #af3585;
  box-shadow: 0px 0px 1px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.cancel_action {
  background: transparent;
  border: 2px solid var(--primary-color);
  box-shadow: 0px 0px 1px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-color) !important;
}
.add_product {
  font-style: normal;
  font-weight: 500;
  font-size: 11.6364px;
  line-height: 150%;
  background: #f9fafb;
  border-radius: 5px;

  color: #1d2939;
  border: 1px solid #1d2939;
}
.add_product:hover {
  background-color: inherit !important;
  color: inherit !important;
}
.bonus_product {
  color: #6b7280;
}
.bonus_product .products {
  position: relative !important;
}

.bonus_product .products img {
  position: absolute;
  top: 25%;
  right: 3%;
}
.product_list p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6b7280;
}
.product_list h5 {
  color: #111827;
}
.content_n_review {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image_wrapper {
  margin-right: 1rem;
}
.product_list button {
  /* white */

  background: #ffffff;
  border-radius: 10px !important;
  color: var(--primary-color);
  font-size: 12px;
  /* gray/300 */

  border: 1px solid var(--primary-color);
  border-radius: 5px;
}
.product_list_content {
  display: flex;
  justify-content: space-between;
}

.text_wrapper {
  flex: 1;
}
.card2 {
  background: #ffffff !important;

  border: 1.12465px solid #e5e7eb;
  box-shadow: 0px 1.12465px 3.37394px rgba(16, 24, 40, 0.1),
    0px 1.12465px 2.24929px rgba(16, 24, 40, 0.06) !important;
  border-radius: 8.99717px !important;
}
.customized_row {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 4px;
}
.modal_content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  color: #1f2937;
}
.modal_content p {
  font-style: normal;
  /* font-weight: 400; */
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* gray/500 */
}
.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}

.modal_content label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  line-height: 20px;
  /* identical to box height, or 143% */

  /* Gray/700 */
}
.modal_content .modal_btn {
  background: var(--primary-color);
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}
.hold_input {
  position: relative !important;
}
.product_selected {
  /* width: 70px; */
  height: 24px;
  padding: 3px 4px 3px 3px;

  /* Base/White */

  background: #ffffff;
  /* Gray/300 */

  border: 1px solid #d0d5dd;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  /* text-align: center; */

  /* Gray/700 */

  color: #344054;
  display: inline-block;
  margin-right: 4px;
}

.hold_input img {
  position: absolute;
  top: 25%;
  /* left: 10px !important; */
}
.hold_input1 img {
  position: absolute;
  top: 28%;
  left: 25px !important;
}
.hold_input2 img {
  position: absolute;
  top: 25%;
  left: 25px !important;
}
.hold_input input {
  background: #ffffff !important;
  /* Gray/300 */

  border: 1px solid #d0d5dd !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}
.retailer_modal_product_img {
  height: 120px;
  width: 40%;
  height: 140px;
  background: #ffffff;
  /* gray/300 */

  border: 0.870748px solid #d1d5db;
  border-radius: 7.77197px;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

indicator {
}

.btn1 {
  background: white;
  border: 1px solid grey;
}

.btn2 {
  padding: 3px 10px;
  font-size: 15px;
}

.p_ {
  font-size: 12px;
  font-weight: 600;
}

@media screen and (max-width: 650px) {
  .marketing_attach_wrap {
    flex-direction: column !important;
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 860px) {
  .cover_products .customized_row {
    display: block !important;
  }
}

@media screen and (max-width: 860px) {
  .product {
    width: 100% !important;
  }
  .card2 {
    width: 40% !important;
  }
  .cover_products .customized_row {
    display: block !important;
  }
}

@media screen and (max-width: 757px) {
  .product {
    width: 100% !important;
  }
  .hide {
    display: none !important;
  }
  .card2 {
    width: 100% !important;
  }
  .cover_products .customized_row1 {
    display: block !important;
  }
  .duration_discount {
    width: 100%;
  }
  .filters .btn {
    width: 100% !important;
    display: none !important;
  }
}

@media only screen and (max-width: 320px) {
  .product {
    background-size: cover !important;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .product {
    background-size: cover !important;
  }
  .retailer_modal_product_img {
    height: 120px;
    width: 100%;
    background-size: contain !important;
  }
  .hold_input img {
    display: none;
    position: absolute;
    top: 28%;
    left: 8%;
  }
}

/* Ads Tab Styles */

.ads_wrapper_ {
  padding: 15px !important;
}

.ads_wrapper_ .header h3 {
  font-weight: 800;
  line-height: 1;
}
.ads_wrapper_ .header p {
  font-size: 14px;
}
.sub_header .p_info h6 {
  font-size: 16px;
  font-weight: 600;
  padding: 0 !important;
  margin: 0 !important;
  line-height: 2;
}

.sub_header .p_info p {
  color: #999 !important;
  font-size: 14px;
  line-height: 1;
}

.sub_header .edit_btn .btn {
  padding: 5px 20px !important;
  float: right;
  font-size: 14px;
}

.ads_wrapper_ .sub_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  left: 15px;
}

.ads_wrapper_ .sub_header input::placeholder {
  font-size: 14px;
}

.ads_wrapper_ .sub_header .edit-btn .btn {
  padding: 7px 20px !important;
  float: right;
}

.ads_wrapper_ .edit-btn .btn {
  font-size: 13px;
}

.ads_wrapper_ .head_wrapper {
  border-bottom: 1px solid #ddd;
}
.ads_wrapper_ .footer_wrapper {
  border-top: 1px solid #ddd;
}
.ads_wrapper_ .show_numbers {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ads_wrapper_ .previous_btn {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 7px 15px;
  cursor: pointer;
  font-size: 14px;
  border-top: none;
}
.ads_wrapper_ .card_table {
  border-radius: 8px;
  overflow: hidden;
}
.ads_wrapper_ .table_section {
  overflow-x: scroll;
}
.ads_wrapper_ .table_section::-webkit-scrollbar,
.ads_wrapper_ .table_section::-webkit-scrollbar-track {
  appearance: none;
  display: none;
}

.ads_wrapper_ table tr,
table td {
  vertical-align: middle;
  white-space: nowrap;
}
.ads_wrapper_ table td button {
  cursor: pointer;
  background: none;
}
.ads_wrapper_ .button_b {
  position: relative;
  top: 0px;
}
.ads_wrapper_ .button_i {
  font-size: 20px;
  line-height: 0;
  position: relative;
  top: 0px;
}
.ads_wrapper_ .dropdown-toggle::after {
  display: none;
}
.ads_wrapper_ .icon_route {
  display: none;
}
.ads_wrapper_ .text_route {
  display: block;
}

.ads_wrapper_ .sponsored_sect {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.ads_wrapper_ .sponsored_sect p a {
  color: #555;
  text-decoration: none;
  font-weight: 600;
}
.ads_wrapper_ .sponsored_sect p:nth-of-type(3) a {
  color: var(--primary-color);
}
.ads_wrapper_ .sponsored_sect p a:hover {
  color: #000;
}
.ads_wrapper_ .accordion button {
  position: relative;
  border: 1px solid #ddd;
  text-decoration: none !important;
  color: #555;
  box-shadow: none !important;
}
.ads_wrapper_ .accordion button i {
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ads_wrapper_ .accordion button:hover {
  color: #111;
}
.ads_wrapper_ .accordion_card {
  padding: 0 0px;
}
.ads_wrapper_ .acc_card_body {
  background: whitesmoke;
}

.cstm_file {
  position: relative;
  height: 150px;
}

.cstm_file .cstm_file_label {
  height: fit-content;
  text-align: center;
}

.cstm_file .cstm_file_label::after {
  display: none !important;
}

.cstm_file input {
  position: absolute;
  height: 80% !important;
  z-index: 999999 !important;
  border: 2px solid black !important;
  cursor: pointer;
}

.cstm_file .cstm_file_label p {
  font-size: 10px !important;
}

.cstm_file .cstm_file_label p span {
  border: none !important;
  color: var(--primary-color);
}

.cstm_file .cstm_file_label p i {
  font-size: 25px !important;
  line-height: 0px;
  background: rgba(100, 100, 100, 0.2);
  padding: 0 6px;
  border-radius: 50%;
}

.row {
  border-top: 1px solid rgba(100, 100, 100, 0.1);
  padding: 20px 0 0px !important;
  margin: 0 !important;
}
.row textarea,
.row input {
  font-size: 12px;
}

.row p {
  font-size: 14px;
  margin-top: 5px;
}

.row div:nth-of-type(1) p {
  /* color: #777; */
  font-weight: bold;
}

.row div:nth-of-type(2) p {
  font-size: 12px;
  font-weight: 600;
}

.row div p span {
  padding: 3px 5px;
  line-height: 0px;
  border-radius: 5px;
  border: 1px solid #999;
}
.modal_btn_outline {
  background-color: white !important;
  color: var(--primary-color) !important;
  border: solid 1px var(--primary-color) !important;
  text-decoration: underline;
}
.view_product {
  color: var(--primary-color) !important;
  cursor: pointer !important;
}
.select_all_pro {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
}

table,
thead,
tr,
tbody,
td {
  white-space: nowrap;
}

td:last-child,
td:last-of-type {
  margin: auto;
}

@media screen and (max-width: 410px) {
  .ads_wrapper_ .sub_header .p_info p {
    font-size: 12px;
  }
  .row p span {
    font-size: 10px;
  }
}
@media screen and (max-width: 570px) {
  .ads_wrapper_ .sub_header .edit_btn .btn {
    float: none !important;
    margin-bottom: 10px;
  }

  .ads_wrapper_ .edit_btn .update_sect {
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 440px) {
  .ads_wrapper_ .icon_route {
    display: block;
  }
  .ads_wrapper_ .text_route {
    display: none;
  }
}

@media screen and (max-width: 410px) {
  .ads_wrapper_ .row p span {
    font-size: 10px;
  }
}
@media screen and (max-width: 570px) {
  .ads_wrapper_ .sub_header .edit-btn .btn {
    float: none !important;
    margin-bottom: 10px;
  }
}
