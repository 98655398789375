
.verification_badge, .unverifed_badge  {
        padding: 2px 0;
        border-radius: 99px;
        font-size: 12px;
        text-align: center;
}

.verification_badge {
    background-color: lightgreen;
    color: green;
}


.unverifed_badge {
    background-color: lightpink;
    color: red;
}

