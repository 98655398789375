* {
  font-family: 'Roboto', sans-serif !important;
}

body {
  font-family: 'Roboto', sans-serif !important;
}

.hold_input {
  position: relative !important;
}

.hold_input img {
  position: absolute;
  top: 20%;
  left: 25px !important;
}

.hold_input1 img {
  position: absolute;
  top: 24%;
  left: 10px !important;
}

.hold_input input {
  background: #ffffff !important;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.export_btn {
  height: 40px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 0 !important;
}

.export_btn:hover {
  color: var(--primary-color);
}

.export_btn:active {
  font-weight: bold;
}

.table {
  width: 100% !important;
}

.thead th {
  color: var(--text-primary) !important;
  border-bottom: 1px solid #eaecf0 !important ;
  font-weight: bold;
  font-size: 16px;
  padding: 16px;
  padding-left: 23px;
  padding-right: 9px;
}

.tr {
  height: 90px !important;
  border-bottom: 1px solid #eaecf0;
}

.thead tr {
  height: 44px;
}

.tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 1px;
  color: #6b7280;
  border-bottom: 1px solid #eaecf0;
  padding-left: 23px;
  padding-right: 9px;
}

.modal_content::-webkit-scrollbar,
.modal_content::-webkit-scrollbar-track {
  appearance: none;
  display: none;
}

.modal_content h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #1f2937;
}

.modal_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #6b7280;
}

.modal_content .modal_header {
  display: flex;
  justify-content: space-between;
}

.modal_content label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.modal_content .modal_btn {
  background: var(--primary-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
}

.customized_row {
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-column-gap: 4px;
}

.cancel_delete {
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  color: #374151;
}

.cancel_delete:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.delete_product {
  background: #dc2626;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 17px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.delete_product:hover {
  color: #dc2626;
}

.card {
  background: var(--primary-color) !important;
  border: 1px solid #e5e7eb !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
  color: #fff;
}

.card span,
small {
  font-weight: bold;
}

.numbers {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.numbers div small {
  font-style: normal;
  font-size: 11px;
  color: #ffffff !important;
}

div.percent {
  background: #d1fae5;
  border-radius: 12px;
  padding: 0px 10px;
  display: inline-block;
  width: '67px';
  height: '25px';
  border-radius: '12px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';
  justify-content: ' space-around';
}

div.percent small {
  font-size: 10px;
}
.MuiPaper-root {
}

.css-3k3duq-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root {
  border-radius: 6px;
  margin-top: 8px !important;
  min-width: 70px !important;
  color: rgb(55, 65, 81);
  left: 430px !important;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  /* padding: 0; */
}

.css-1gnd1fd-MuiButton-endIcon > *:nth-of-type(1) {
  font-size: 20px !important;
  background: #fff !important;
  color: var(--primary-color) !important;
  border-radius: 7px !important;
}
